import React from 'react'

import './coreRadioButton.scss'

interface IButtonProps {
  fontStyle?: React.CSSProperties
  label: string
  value: string
  disabled?: boolean
}

interface ICustomRadioButton {
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  radioGroupe: string
  buttons: IButtonProps[]
  defaultValue?: string
  className?: string
  onClick: (checked: string) => void
}

interface singleRadioButton extends IButtonProps {
  radioGroupe?: string
  defaultChecked?: boolean
  onClick: (checked: string) => void
}

const SingeRadioButton = ({
  onClick,
  radioGroupe,
  label,
  value,
  fontStyle,
  disabled,
  defaultChecked,
  ...props
}: singleRadioButton) => {
  const handleClick = event => {
    event.stopPropagation()
    onClick && onClick(event.target.value)
  }
  return (
    <>
      <input
        {...props}
        data-test={`radio-button-${label}`}
        disabled={disabled}
        value={value}
        onChange={handleClick}
        type='radio'
        name={radioGroupe}
        defaultChecked={defaultChecked}
        id={label}
      />
      <label htmlFor={label}>
        <span data-test={`radio-button-clickable-${label}`} className='radio-button'></span>
        <p data-test={`radio-button-label-${label}`} style={{ ...fontStyle }}>
          {label}
        </p>
      </label>
    </>
  )
}

const CoreRadioButton = ({
  flexDirection = 'column',
  onClick,
  radioGroupe,
  buttons,
  defaultValue,
  className,
  ...props
}: ICustomRadioButton) => {
  return (
    <div
      data-test={`radio-group-${radioGroupe}`}
      className={`${className} custom-radio-button`}
      style={{ flexDirection: flexDirection, width: '100%' }}>
      {buttons.map((button: IButtonProps) => {
        return (
          <SingeRadioButton
            value={button.value || button.label}
            defaultChecked={button.value === defaultValue || button.label === defaultValue}
            fontStyle={button.fontStyle}
            radioGroupe={radioGroupe}
            disabled={button.disabled}
            label={button.label}
            key={button.label}
            onClick={onClick}
            {...props}
          />
        )
      })}
    </div>
  )
}

export default React.memo(CoreRadioButton)
