import { NavLink } from 'react-router-dom'

import MerchantLogo from '@components/MerchantLogo/MerchantLogo'
import { useAppDispatch } from '@hooks'
import ArrowDown from '@images/icons/chevron-down.svg?react'
import LogoutIcon from '@images/icons/logout.svg?react'
import SettingsIcon from '@images/icons/settings.svg?react'
import cn from 'classnames'

import { api, paths, useLazyCardLogoutQuery, useLazyLogoutQuery } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import './profileDropdown.scss'

interface ProfileDropdownProps {
  onClick: () => void
  merchantName: string
  dropDownOpened?: boolean
}
const ProfileDropdown = ({ merchantName, onClick, dropDownOpened = false }: ProfileDropdownProps) => {
  const [logout] = useLazyLogoutQuery()
  const [cardLogout] = useLazyCardLogoutQuery()

  const dispatch = useAppDispatch()

  const handleLogoutClick = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    await cardLogout().unwrap()
    await logout().unwrap()
    dispatch(api.util.resetApiState())
    return false
  }

  return (
    <div className={cn('profile-dropdown-wrapper', { opened: dropDownOpened })} onClick={() => onClick && onClick()}>
      <MerchantLogo merchantName={merchantName} />

      {merchantName}

      <ArrowDown className={cn({ flipped: dropDownOpened })} />

      {dropDownOpened && (
        <div className='user-dropdown-menu-container'>
          <NavLink date-test='profile-dropdown-menu-settings' to={paths.settings()} className='menu-item'>
            <SettingsIcon />
            {tr('FRONTEND.HEADER.SETTINGS', 'Manage account')}
          </NavLink>
          <NavLink date-test='profile-dropdown-menu-logout' to='#' className='menu-item' onClick={handleLogoutClick}>
            <LogoutIcon />
            {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
          </NavLink>
        </div>
      )}
    </div>
  )
}

export default ProfileDropdown
