import CurrencyIcon from '@components/CurrencyIcon'
import FlagIcon from '@components/FlagIcon'
import ArrowDownIcon from '@images/icons/chevron-down.svg?react'

import { formatMoney, tr } from 'mmfintech-commons'

import './selectionElements.scss'
import type { SelectionElementProps } from './selectionTypes'

import SelectAccountImage from '@images/account-select.png'

const SelectionElement = ({
  onSelect,
  data,
  type,
  setInputFocused,
  isSelected = false,
  hideCaption
}: SelectionElementProps) => {
  switch (type) {
    case 'account':
      if (data === 'all-accounts') {
        return (
          <div
            className='selection-element-wrapper'
            onClick={() => {
              onSelect && onSelect(null)
            }}>
            <img src={SelectAccountImage} width={30} alt='' />
            <div className='selection-element-content pl-3'>
              <div data-test='selection-element-account-currency-code' className='body-medium'>
                {tr('FRONTEND.INPUT.SELECT.ALL_ACCOUNTS', 'All accounts')}
              </div>
              {isSelected && (
                <div data-test='selection-element-account-arrow-icon' className='selection-element-right-icon'>
                  {<ArrowDownIcon onClick={() => setInputFocused(true)} />}
                </div>
              )}
            </div>
          </div>
        )
      }
      return (
        <div
          className='selection-element-wrapper'
          onClick={() => {
            onSelect && onSelect(data)
          }}>
          <CurrencyIcon currency={data.currencyCode} width='30px' height='30px' circle />
          <div className='selection-element-content pl-3'>
            <div data-test='selection-element-account-currency-code' className='body-medium'>
              {data.name || `${data.currencyCode} account`}
            </div>
            {!hideCaption && (
              <div>
                <span data-test='selection-element-account-caption-label' className='selection-element-caption-label'>
                  {tr('FRONTEND.INPUT.SELECT.BALANCE', 'Balance')}
                  {': '}
                </span>
                <span
                  translate='no'
                  data-test='selection-element-account-caption-value'
                  className='selection-element-caption-text'>
                  {formatMoney(data?.balance, data?.currencyCode)}
                </span>
              </div>
            )}
            {isSelected && (
              <div data-test='selection-element-account-arrow-icon' className='selection-element-right-icon'>
                {<ArrowDownIcon onClick={() => setInputFocused(true)} />}
              </div>
            )}
          </div>
        </div>
      )
    case 'country':
      return (
        <div
          className='selection-element-wrapper'
          onClick={() => {
            onSelect && onSelect(data)
          }}>
          <FlagIcon countryCode={data.value} width='auto' height='20px' />
          <div className='selection-element-content pl-3'>
            <div data-test='selection-element-country-label' className='body-medium'>
              {data.label}
            </div>
            {isSelected && (
              <div data-test='selection-element-country-icon' className='selection-element-right-icon'>
                {<ArrowDownIcon onClick={() => setInputFocused(true)} />}
              </div>
            )}
          </div>
        </div>
      )
    case 'date':
      return (
        <div
          className='selection-element-wrapper'
          onClick={() => {
            onSelect && onSelect(data)
          }}>
          <div className='selection-element-content pl-3'>
            <div data-test='selection-element-date-label' className='body-medium'>
              {data}
            </div>
            {isSelected && (
              <div data-test='selection-element-date-arrow-icon' className='selection-element-right-icon'>
                {<ArrowDownIcon onClick={() => setInputFocused(true)} />}
              </div>
            )}
          </div>
        </div>
      )
    case 'default':
      return (
        <div
          className='selection-element-wrapper'
          onClick={() => {
            onSelect && onSelect(data)
          }}>
          <div className='selection-element-content pl-3'>
            <div data-test='selection-element-label' className='body-medium'>
              {data.label}
            </div>
            {isSelected && (
              <div data-test='selection-element-arrow-icon' className='selection-element-right-icon'>
                {<ArrowDownIcon onClick={() => setInputFocused(true)} />}
              </div>
            )}
          </div>
        </div>
      )
  }
}

export default SelectionElement
