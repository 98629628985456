import { configuration, initialize } from 'mmfintech-backend-api'

initialize(import.meta.env)

const cookieDomain = window.location.hostname.substring(window.location.hostname.indexOf('.'))
const domain = cookieDomain.substring(1)

const settings = {
  cookieDomain: configuration.isLocal() ? null : cookieDomain,
  customerServiceUrl: `mailto:support@${domain}`,
  landingPageUrl:
    configuration.isDevelopment() || configuration.isSandbox() ? `https://sandbox.${domain}` : `https://${domain}`,
  languages: ['en', 'de', 'es', 'fr', 'it', 'no', 'pl', 'pt', 'ja'],
  loginPageVideoUrl: 'https://videos-public-mmfintech.s3.eu-central-1.amazonaws.com/jetonapp/welcome-video.mp4',
  verifyPageVideoUrl: 'https://videos-public-mmfintech.s3.eu-central-1.amazonaws.com/jetonapp/verify-video.mp4',
  missingCardVideoUrl:
    'https://videos-public-mmfintech.s3.eu-central-1.amazonaws.com/jetonapp/jeton-card-animaiton.mp4',
  cardPodiumVideoUrl: 'https://videos-public-mmfintech.s3.eu-central-1.amazonaws.com/jetonapp/card-podium.mp4',

  defaultUploadInvoiceAcceptType: ['pdf', 'jpg', 'jpeg', 'png'],
  defaultUploadInvoiceMaxFileSize: 1024 * 1024,
  logoAcceptType: ['jpg', 'jpeg', 'gif', 'png', 'svg'],
  logoMaxFileSize: 1024 * 1024 * 10,
  questionnaireFileTypes: ['pdf', 'jpg', 'jpeg', 'png'],
  questionnaireMaxFileSize: 1024 * 1024 * 10
}

export default settings
