import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import CoreButton from '@components/CoreButton'
import CoreInput from '@components/CoreInput'
import CoreSelect from '@components/CoreSelect'
import CoreStepper from '@components/CoreStepper'
import LeftArrow from '@images/icons/left-arrow-button.svg?react'
import PhoneIcon from '@images/icons/phone.svg?react'

import {
  paths,
  useChange3dsPhoneMutation,
  useGetProfileQuery,
  useGetSupportedCardCurrenciesQuery,
  useIssuePrepaidCardMutation
} from 'mmfintech-backend-api'
import {
  GlobalContext,
  OtpContext,
  checkFinalValue,
  fixPhoneNumber,
  isValidArray,
  isValidString,
  tr,
  translateError,
  useFormValues
} from 'mmfintech-commons'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import { CardOrderForm } from './CardOrderForm'
import { CardOrderTypeSelection } from './CardOrderTypeSelection'
import './cardOrderModal.scss'

import SuccessImage from '@images/deposit-success.png'

interface CardOrderModalProps {
  onClose?: () => void
  currentStep?: number
  selectedCardType?: 'physical' | 'virtual'
  issueCard?: boolean
  passedData?: any
  error?: any
  cardBrand?: string
  cardCurrency?: string
}

const CardOrderModal = (props: CardOrderModalProps) => {
  const { onClose, currentStep, selectedCardType, issueCard, passedData, error, cardBrand, cardCurrency } = props

  const { setOtpOnSuccess } = useContext(OtpContext)
  const { modalShow, modalHide } = useContext(GlobalContext)

  const [step, setStep] = useState<number>(currentStep || 1)
  const [cardType, setCardType] = useState<'physical' | 'virtual'>(selectedCardType)
  const [phoneNumber, setPhoneNumber] = useState<string>(null)
  const [issuingError, setIssuingError] = useState(null)
  const [selectedCardBrand, setSelectedCardBrand] = useState(cardBrand)

  const [enableCurrencySelect, setEnableCurrencySelect] = useState<boolean>(null)

  const [selectedCardCurrency, setSelectedCardCurrency] = useState<string>(cardCurrency)
  const [cardCurrencies, setCardCurrencies] = useState(null)

  const history = useHistory()

  const formValues = useFormValues({
    // cardHolderName: { required: true, validation: 'name', maxLength: 26, minLength: 3 },
    city: { required: true, validation: 'alphaNumeric+' },
    countryCode: { required: true },
    // dateOfBirth: { required: true, validation: 'safe-string' },
    // firstName: { required: true, validation: 'name' },
    // lastName: { required: true, validation: 'name' },
    phoneNumber: { required: true, validation: 'phone' },
    // title: { required: false },
    street: { required: true, validation: 'alphaNumeric+' },
    streetNumber: { required: true, validation: 'alphaNumeric+' },
    postalCode: { required: true, validation: 'alphaNumeric+' }
  })

  const [orderCard, { isLoading, error: cardIssueError }] = useIssuePrepaidCardMutation()
  const [changePhone, { error: changePhoneError, isLoading: changePhoneLoading }] = useChange3dsPhoneMutation()

  const {
    data: supportedCardCurrencies,
    isLoading: supportedCardCurrenciesLoading,
    error: supportedCardCurrenciesError
  } = useGetSupportedCardCurrenciesQuery(null)

  const {
    hasValidatedPhone,
    phoneChecked,
    twoFactorPhone,
    phoneCheckLoading,
    refetch: recheckPhone
  } = useGetProfileQuery(null, {
    selectFromResult: ({ data, isSuccess, isLoading }) => {
      return {
        hasValidatedPhone: !!data?.phone3dsVerified,
        twoFactorPhone: data?.twoFactorPhone,
        phoneChecked: isSuccess,
        phoneCheckLoading: isLoading
      }
    }
  })

  const shouldSetPhone = cardType === 'virtual' && phoneChecked && !hasValidatedPhone

  const handlePhoneChange = async () => {
    setOtpOnSuccess(() => async (response: any) => {
      if (response) {
        setTimeout(() => {
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={2}
                selectedCardType={'virtual'}
                cardBrand={selectedCardBrand}
                cardCurrency={selectedCardCurrency}
              />
            )
          })
        }, 0)
      }
    })
    try {
      const state = checkFinalValue(phoneNumber, { validation: 'phone', required: true })

      if (state.valid) {
        const result = await changePhone({ phone: fixPhoneNumber(phoneNumber) }).unwrap()
        if (result && !result.challenge?.challengeId) {
          await recheckPhone().unwrap()
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={2}
                selectedCardType={'virtual'}
                cardBrand={selectedCardBrand}
                cardCurrency={selectedCardCurrency}
              />
            )
          })
        }
      } else {
        setIssuingError(translateError(state))
      }
    } catch (error) {
      return error
    }
  }

  const handleSubmit = async () => {
    setOtpOnSuccess(() => (response: any) => {
      if (response && response.externalCardId) {
        setTimeout(() => {
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={3}
                selectedCardType={cardType}
                cardBrand={selectedCardBrand}
                cardCurrency={selectedCardCurrency}
              />
            )
          })
        }, 0)
      }

      if (response && !response.externalCardId) {
        setTimeout(() => {
          modalShow({
            header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
            content: (
              <CardOrderModal
                onClose={modalHide}
                currentStep={1}
                error={tr('FRONTEND.DASHBOARD.ORDER_CARDS.GENERAL_ERROR', 'Issuing failed')}
                cardBrand={selectedCardBrand}
                cardCurrency={selectedCardCurrency}
              />
            )
          })
        }, 0)
      }
    })
    try {
      let data = {} as any
      if (cardType === 'virtual') {
        data = {
          isVirtual: true,
          currencyCode: selectedCardCurrency,
          cardBrand: selectedCardBrand
        }
      } else {
        if (formValues.areValid()) {
          data = {
            isVirtual: false,
            currency: selectedCardCurrency,
            cardBrand: selectedCardBrand,
            address: {
              country: formValues.getValue('countryCode'),
              streetName: formValues.getValue('street'),
              streetNumber: formValues.getValue('streetNumber'),
              zipCode: formValues.getValue('postalCode'),
              city: formValues.getValue('city')
            }
          }
        } else {
          return null
        }
      }
      if (cardType === 'physical' && !hasValidatedPhone) {
        setOtpOnSuccess(() => (response: any) => {
          if (response) {
            setTimeout(() => {
              modalShow({
                header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
                content: (
                  <CardOrderModal
                    onClose={modalHide}
                    currentStep={2}
                    selectedCardType={cardType}
                    issueCard={true}
                    passedData={formValues.prepare()}
                    cardBrand={selectedCardBrand}
                    cardCurrency={selectedCardCurrency}
                  />
                )
              })
            }, 0)
          }
        })
        const changeResult = await changePhone({ phone: fixPhoneNumber(formValues.getValue('phoneNumber')) }).unwrap()
        if (changeResult.challenge) {
          return changeResult
        }
      }

      const result = await orderCard(data).unwrap()
      if (step !== 3 && result && result.externalCardId) {
        setStep(step => step + 1)
      }
      if (result && !result.externalCardId) {
        setIssuingError(tr('FRONTEND.DASHBOARD.ORDER_CARDS.GENERAL_ERROR', 'Issuing failed'))
      }
      return result
    } catch (err) {
      return err
    }
  }

  useEffect(() => {
    if (step == 2 && cardType == 'virtual' && hasValidatedPhone) {
      void handleSubmit()
    }
    if (step == 2 && cardType == 'physical' && issueCard) {
      if (formValues && passedData) {
        Object.entries(passedData).forEach(el => {
          const [key, value] = el
          formValues.setValue(key, value)
        })
        if (passedData.address) {
          Object.entries(passedData.address).forEach(el => {
            const [key, value] = el
            formValues.setValue(key, value)
          })
        }
      }

      void handleSubmit()
    }
  }, [step, hasValidatedPhone, issueCard])

  useEffect(() => {
    if (isValidString(twoFactorPhone)) {
      setPhoneNumber(twoFactorPhone)
    }
  }, [phoneChecked])

  useEffect(() => {
    if (error) {
      setIssuingError(error)
    }
  }, [error])

  useEffect(() => {
    if (isValidArray(supportedCardCurrencies) && !selectedCardCurrency) {
      const currencies = supportedCardCurrencies.map(currency => {
        return { value: currency.currencyCode, label: currency.currencyCode }
      })
      setCardCurrencies(currencies)
      setSelectedCardCurrency(currencies[0].value)
      if (currencies.length > 1 && !currentStep) {
        setEnableCurrencySelect(true)
        setStep(0)
      }
    }
  }, [supportedCardCurrencies])

  return (
    <div className='card-order-modal-wrapper'>
      {supportedCardCurrenciesLoading ? (
        <Spinner />
      ) : (
        <>
          {step === 0 && enableCurrencySelect && (
            <div className='card-order-currency-select'>
              <CoreSelect
                type='default'
                options={cardCurrencies}
                value={selectedCardCurrency}
                onChange={(_, value) => {
                  setSelectedCardCurrency(value)
                }}
              />
            </div>
          )}

          {step === 1 && (
            <CardOrderTypeSelection
              cardType={cardType}
              setCardType={setCardType}
              selectedCardBrand={selectedCardBrand}
              setSelectedCardBrand={setSelectedCardBrand}
              selectedCardCurrency={selectedCardCurrency}
              supportedCardCurrenciesError={supportedCardCurrenciesError}
              issuingError={issuingError}
            />
          )}

          {step === 2 && cardType === 'physical' && (
            <CardOrderForm
              formValues={formValues}
              cardIssueError={cardIssueError}
              cardIssueLoading={isLoading}
              changePhoneError={changePhoneError || issuingError}
            />
          )}

          {step === 3 && (
            <div className='card-order-success-wrapper'>
              <div className='card-order-success-title'>
                {tr('FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.TITLE', 'You have successfully ordered Jeton Card!')}
              </div>
              <div className='card-order-success-subtitle'>
                {cardType == 'physical'
                  ? tr(
                      'FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.SUBTITLE',
                      'Your card will be ready to be delivered within 1-2 weeks.'
                    )
                  : tr(
                      'FRONTEND.DASHBOARD.CARDS_ORDER.SUCCESS.VIRTUAL',
                      'Your card will be available in the Cards menu.'
                    )}
              </div>
              <img src={SuccessImage} alt='success-image' />
            </div>
          )}
        </>
      )}

      <div className='card-order-stepper'>
        <CoreStepper currentStep={enableCurrencySelect ? step + 1 : step} totalSteps={enableCurrencySelect ? 4 : 3} />
      </div>

      {step === 2 &&
        cardType === 'virtual' &&
        (isLoading ? (
          <div className='card-order-issuing-message'>
            <Spinner />
            {tr('FRONTEND.DASHBOARD.CARDS_ORDER.ISSUING_MESSAGE', 'Your virtual card is now being issued.')}
          </div>
        ) : (
          <>
            {phoneCheckLoading && <Spinner />}
            <ErrorDisplay error={cardIssueError?.error || cardIssueError || changePhoneError} />
          </>
        ))}

      {step == 2 && shouldSetPhone && (
        <div className='card-order-phone-section'>
          <div className='card-order-phone-icon'>
            <PhoneIcon />
          </div>
          <div className='card-order-phone-instructions'>
            {tr(
              'FRONTEND.DASHBOARD.CARDS_ORDER.PHONE_INSTRUCTIONS',
              "Please enter your phone number. We'll send you a code via SMS"
            )}
          </div>
          <CoreInput
            label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.PHONE_NUMBER', 'Phone number')}
            type='phone'
            onChange={(_: any, value: string) => {
              setPhoneNumber(value)
              setIssuingError(null)
            }}
            value={phoneNumber}
          />
          <ErrorDisplay error={issuingError} />
        </div>
      )}

      <div className='card-order-modal-buttons-wrapper'>
        {!isLoading && step < 3 && ((enableCurrencySelect && step > 0) || step > 1) ? (
          <CoreButton
            LeftIcon={<LeftArrow />}
            size='normal'
            variation='secondary'
            title={tr('FRONTEND.DASHBOARD.CARDS_ORDER.BACK_BUTTON', 'Back')}
            className='card-order-modal-back-button'
            isLoading={changePhoneLoading}
            onClick={async () => {
              if (step > 1 || (enableCurrencySelect && step > 0)) {
                setStep(step - 1)
              }
            }}
          />
        ) : null}

        {!(step === 2 && isLoading) && !(cardIssueError && cardType !== 'physical') && (
          <CoreButton
            fullWidth
            size='normal'
            disabled={step === 1 && !cardType}
            title={
              step === 1
                ? tr('FRONTEND.DASHBOARD.CARDS_ORDER.CONTINUE_BUTTON', 'Continue')
                : step === 2
                ? tr('FRONTEND.DASHBOARD.CARDS_ORDER.COMPLETE_BUTTON', 'Accept and complete order')
                : tr('FRONTEND.DASHBOARD.CARDS_ORDER.FINISH_BUTTON', 'Continue to Overview')
            }
            isLoading={isLoading || changePhoneLoading}
            onClick={async () => {
              if (step === 3) {
                onClose && onClose()
                return history.push(paths.dashboard())
              }
              if (step === 2) {
                if ((phoneChecked && hasValidatedPhone) || cardType === 'physical') {
                  return await handleSubmit()
                } else {
                  return await handlePhoneChange()
                }
              }
              if (step !== 3) {
                setStep(step => step + 1)
              }
            }}
          />
        )}
      </div>
    </div>
  )
}

export default CardOrderModal
