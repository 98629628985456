import { memo } from 'react'

import cn from 'classnames'
import isEqual from 'lodash.isequal'

import './sideBarInnerRight.scss'

interface SidebarInnerRightProps {
  content: React.FC
  options: {
    closeIconPosition?: 'default'
    closeOnClickOutside?: boolean
    closeOnEscape?: boolean
    hideCloseIcon?: boolean
    scrollable?: boolean
    maxWidth?: number
    onClose?: () => void
    transparent?: boolean
    size?: 'auto' | 'small' | 'medium' | 'large' | 'extra-large' | 'horizontal' | 'centered'
  }
  visible: boolean
}

const SidebarInnerRight: React.FC<SidebarInnerRightProps> = ({ content, options = {}, visible }) => {
  return (
    <div className={cn('sidebar-inner-right-wrapper', { visible: !!visible })}>
      <div
        data-test='modal-container'
        className={cn('sidebar-inner-right-container', {
          visible: !!visible,
          'completely-closed': !visible && !content,
          'size-auto': options?.size === 'auto',
          'size-large': options?.size === 'large',
          'size-small': options?.size === 'small',
          'size-extra-large': options?.size === 'extra-large',
          centered: options?.size === 'centered',
          horizontal: options?.size === 'horizontal',
          transparent: options?.transparent === true
        })}>
        {content}
      </div>
    </div>
  )
}

export default memo(SidebarInnerRight, (prevProps, nextProps) => isEqual(prevProps, nextProps))
