import { FC } from 'react'
import { Link } from 'react-router-dom'

import CustomerServiceIcon from '@images/icons/profile-customer-service-icon.svg?react'
import settings from '@settings'
import '@views/settings/styled/profile.scss'

import { tr } from 'mmfintech-commons'

import './helpSection.scss'

const HelpSection: FC = () => {
  return (
    <div className='help-wrapper'>
      <div className='help-inner'>
        <h4>{tr('FRONTEND.HELP.TITLE', 'Help')}</h4>
        <div
          className='preview-profile-content-wrapper'
          onClick={() => window.open('https://jetonhelp.zendesk.com/', '_blank')}>
          <CustomerServiceIcon />
          <div className='preview-content'>
            <span>{tr('FRONTEND.HELP.CONTACT_TEXT', 'Contact support')}</span>
            <p>{tr('FRONTEND.HELP.CONTACT_SUBTEXT', 'In case you need help')}</p>
          </div>
        </div>
      </div>
      <div className='links'>
        <Link to={{ pathname: settings.landingPageUrl + '/privacy-policy' }} target='_blank' data-test='privacy'>
          <span>{tr('FRONTEND.FOOTER.PRIVACY', 'Privacy')}</span>
        </Link>
        <span className='dot' />
        <Link
          to={{ pathname: settings.landingPageUrl + '/terms-and-conditions' }}
          target='_blank'
          data-test='terms-and-conditions'>
          <span>{tr('FRONTEND.FOOTER.TOC', 'Terms & Conditions')}</span>
        </Link>
      </div>
    </div>
  )
}

export default HelpSection
