import { initReactI18next } from 'react-i18next'

import i18next from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'

import settings from '@settings'

const options = {
  debug: false,
  lng: 'en',
  fallbackLng: 'en',
  supportedLngs: ['en-US', ...settings.languages],
  ns: 'translation',
  defaultNS: 'translation',
  react: {
    useSuspense: true
  },
  backend: {
    backends: [HttpBackend],
    backendOptions: [{ loadPath: '/i18n/{{lng}}.json' }]
  }
}

i18next
  .use(ChainedBackend)
  .use(initReactI18next)
  .init(options)
  // @ts-ignore
  .then((t, key) => t(key))

export default i18next
