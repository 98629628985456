import {
  isOwnerOrAdministrator,
  selectCurrentUserId,
  selectCurrentUserRole,
  useAppSelector,
  useGetMerchantUserAccessQuery,
  useGetMyPermissionsQuery
} from 'mmfintech-backend-api'

// const READ_PERMISSION = 'READER'
const WRITE_PERMISSION = 'WRITER'

export const useUserPermissions = (props?: { customerId?: number }) => {
  const { customerId } = props || {}
  const userId = useAppSelector(selectCurrentUserId)
  const {
    data: userPermissions,
    isFetching: userPermissionsFetching,
    error: userPermissionsError
  } = useGetMerchantUserAccessQuery(
    { userId: customerId },
    {
      skip: !customerId
    }
  )
  const {
    data: myPermissions,
    isFetching: myPermissionsFetching,
    error: myPermissionsError
  } = useGetMyPermissionsQuery(userId, {
    skip: !!customerId
  })

  return {
    isLoading: myPermissionsFetching || userPermissionsFetching,
    error: myPermissionsError || userPermissionsError,
    permissions: myPermissions || userPermissions
  }
}

export const useHasWritePermissions = (accountId?: string) => {
  const { isLoading, error, permissions } = useUserPermissions()
  const customerRole = useAppSelector(selectCurrentUserRole)

  if (isOwnerOrAdministrator(customerRole)) return true
  if (isLoading || error) return false

  if (accountId) {
    const permission = permissions?.find(
      (perm: any) => perm.accountId === accountId && perm.accessLevel === WRITE_PERMISSION
    )

    return !!permission
  }

  const permission = permissions?.find((perm: any) => perm.accessLevel === WRITE_PERMISSION)
  return !!permission
}
