import { memo } from 'react'

import isEqual from 'lodash.isequal'

import SideBarInnerRight from '../SideBar/SideBarInnerRight'
import './contentWrapper.scss'

interface ContentWrapperProps {
  children?: JSX.Element
  sideBarInner?: any
}

function ContentWrapper({ children, sideBarInner }: ContentWrapperProps) {
  return (
    <div className='content-wrapper'>
      <>{children}</>
      <SideBarInnerRight {...sideBarInner} />
    </div>
  )
}

export default memo(ContentWrapper, (prevProps, nextProps) => isEqual(prevProps, nextProps))
