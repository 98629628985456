import { Link } from 'react-router-dom'

import { paths } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import './styled/noTransactionsMessage.scss'

const NoTransactionsMessage = ({ iconLink, hideSubtext = false }: { iconLink?: string; hideSubtext?: boolean }) => {
  return (
    <div className='no-transactions-message-wrapper'>
      {iconLink && <img src={iconLink} alt='no-transactions-icon' />}
      <p data-test='no-transactions-title' className='no-transactions-message'>
        {tr('FRONTEND.TRANSACTIONS.NO_TRANSACTIONS_TEXT', 'Your recent transactions will show here!')}
      </p>
      {!hideSubtext && (
        <p data-test='no-transactions-subtitle' className='no-transactions-subtext'>
          <span data-test='no-transactions-context'>
            {tr(
              'FRONTEND.TRANSACTIONS.NO_TRANSACTIONS_SUBTEXT',
              "You don't have any transactions yet. You can start now with your first"
            )}
          </span>
          <Link to={paths.banking.deposit()} data-test='no-transactions-deposit'>
            {tr('FRONTEND.TRANSACTIONS.NO_TRANSACTIONS_LINK', 'Deposit')}
          </Link>
        </p>
      )}
    </div>
  )
}

export default NoTransactionsMessage
