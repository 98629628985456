// noinspection DuplicatedCode
import CoreCheckbox from '@components/CoreCheckbox'
import cn from 'classnames'

import { tr } from 'mmfintech-commons'

import './styled/checkBoxGroup.scss'

interface ICheckBoxGroupProps {
  items: []
  checked?: (arg0: string) => boolean
  setChecked?: (checked: boolean) => void
}

function CheckBoxGroup({ items, checked, setChecked }: ICheckBoxGroupProps) {
  return (
    <div className='check-box-group-wrapper' onClick={e => e.stopPropagation()}>
      <div className='item-container'>
        {items?.map((item, index) => {
          const { name, label, localizationKey } = item
          return (
            <div
              data-test={`checkbox-wrapper-${name}`}
              className='check-box-group'
              key={index}
              onClick={() => setChecked(name)}>
              <CoreCheckbox
                data-test={`checkbox-icon-${name}`}
                style={{ width: 'unset' }}
                checked={checked(name)}
                type={'circle'}
                value={name}
                onClick={null}
              />
              <span data-test={`checkbox-label-${name}`}>{tr(localizationKey, label)}</span>
              <span className={cn('checkbox', { checked: checked(name) })} data-test={`checkbox-toggle-${name}`} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CheckBoxGroup
