import { useContext, useEffect, useState } from 'react'

import SnowFallBackground from '@components/CoreSnowFallBackground'

import { useAppSelector, useAuth } from 'mmfintech-backend-api'
import { GlobalContext } from 'mmfintech-commons'

import CoreButton from '../CoreButton'
import CoreCheckbox from '../CoreCheckbox'
import CoreMenu from '../CoreMenu'
import CoreRadioButton from '../CoreRadioButton'
import CoreStatusView from '../CoreStatusView'
import CoreTabs from '../CoreTabs'
import { Otp, prepareTitle } from '../Otp'

import AccountsIcon from '@images/icons/menu-accounts-icon.svg'
import MenuHoseIcon from '@images/icons/menu-icon-home.svg'
import RecipientIcon from '@images/icons/menu-recipients-icon.svg'
import TransactionIcon from '@images/icons/menu-transaction-icon.svg'
import { TransactionStatusEnum } from 'mmfintech-commons-types'

const demoMenuitems = [
  {
    imageSrc: MenuHoseIcon,
    label: 'Home',
    path: '/'
  },
  {
    imageSrc: TransactionIcon,
    label: 'Home2',
    path: '/',
    onClick: () => console.log('something super cool')
  },
  {
    imageSrc: RecipientIcon,
    label: 'Home3',
    path: '/'
  },
  {
    imageSrc: AccountsIcon,
    label: 'Home4',
    path: '/'
  }
]

const demoTabs = [
  {
    label: 'label1',
    value: 'label1'
  },
  {
    label: 'label2',
    value: 'label2'
  },
  {
    label: 'label3aasdasdasd',
    value: 'label3aasdasdasd'
  },
  {
    label: 'label4',
    value: 'label4'
  },
  {
    label: 'label3aasdasdasd2',
    value: 'label3aasdasdasd2'
  },
  {
    label: 'label42',
    value: 'label42'
  },
  {
    label: 'label3aasdasdasd3',
    value: 'label3aasdasdasd3'
  },
  {
    label: 'label43',
    value: 'label43'
  }
]

const radioButtonsDemo: any = [
  {
    fontStyle: {
      fontSize: '12px',
      fontWeight: 'bold'
    },
    label: 'demo-radio-1',
    value: 'demo-radio-1-value',
    disabled: false
  },
  {
    fontStyle: {
      fontSize: '14px',
      fontWeight: 'bold'
    },
    label: 'demo-radio-2',
    value: 'demo-radio-value',
    disabled: false
  },
  {
    fontStyle: {
      fontSize: '16px',
      fontWeight: 'bold'
    },
    label: 'demo-radio-3',
    disabled: false
  },
  {
    fontStyle: {
      fontSize: '18px',
      fontWeight: 'bold'
    },
    label: 'demo-radio-4/disabled',
    disabled: true
  }
]

function DemoStyleViewer() {
  const [dashChecked, setDashChecked] = useState<boolean>(false)
  const [checkChecked, setCheckChecked] = useState<boolean>(false)
  const [circleChecked, setCircleChecked] = useState<boolean>(false)
  const [radioButtonValue, setRadioButtonValue] = useState<string>('')
  const userStatus = useAuth()
  const queryChallenge = useAppSelector(state => state.challenge)
  const { twoFactorType } = queryChallenge || {}
  const { modalShow, modalHide } = useContext(GlobalContext)
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    open
      ? modalShow({
          header: prepareTitle({ twoFactorType, userStatus }),
          options: {
            size: 'auto'
          },
          content: <Otp />
        })
      : modalHide()
  }, [open])

  const [selectTab, setSelectTab] = useState<string>(null)
  return (
    <SnowFallBackground>
      <div
        style={{
          display: 'flex',
          overflow: 'auto',
          width: '100%',
          padding: '40px'
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h5 style={{ margin: 0 }}>Variation: primary</h5>
          <h5>full Width</h5>
          <div style={{ width: '250px', marginBottom: '10px', marginLeft: '10px' }}>
            <CoreButton
              title='Default'
              onClick={() => console.log('clicked')}
              variation='primary'
              size='small'
              LeftIcon={<span className='icon'>+</span>}
              RightIcon={<span className='icon'>+</span>}
              fullWidth
            />
          </div>
          <div style={{ width: '250px', marginBottom: '10px', marginLeft: '10px' }}>
            <CoreButton
              title='Default'
              onClick={() => console.log('clicked')}
              variation='primary'
              size='small'
              LeftIcon={<span className='icon'>+</span>}
              RightIcon={<span className='icon'>+</span>}
              width='50px'
            />
          </div>

          <p>size</p>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='primary'
            size='small'
            LeftIcon={<span className='icon'>+</span>}
            RightIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='primary'
            size='normal'
            LeftIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='primary'
            size='large'
            RightIcon={<span className='icon'>+</span>}
          />
          <p>disable</p>
          <CoreButton
            title='Default'
            disabled={true}
            onClick={() => console.log('clicked')}
            variation='primary'
            size='small'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <p>collapsed</p>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='primary'
            size='small'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='primary'
            size='normal'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='primary'
            size='large'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            disabled={true}
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='primary'
            size='small'
            LeftIcon={<span className='icon'>+</span>}
          />
          <p>isLoading</p>
          <CoreButton
            collapsed={true}
            isLoading={true}
            title='Default'
            onClick={() => console.log('clicked')}
            variation='primary'
            size='small'
            LeftIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            collapsed={true}
            isLoading={true}
            title='Default'
            onClick={() => console.log('clicked')}
            variation='primary'
            size='normal'
            LeftIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='primary'
            size='large'
            isLoading={true}
          />
        </div>
        {/* secondaryButtons */}
        <div
          style={{
            marginLeft: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <h5 style={{ margin: 0 }}>Variation: secondary</h5>
          <h5>full Width</h5>
          <div style={{ width: '250px', marginBottom: '10px' }}>
            <CoreButton
              title='Default'
              onClick={() => console.log('clicked')}
              variation='secondary'
              size='small'
              LeftIcon={<span className='icon'>+</span>}
              RightIcon={<span className='icon'>+</span>}
              fullWidth
            />
          </div>
          <p>size</p>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='secondary'
            size='small'
            LeftIcon={<span className='icon'>+</span>}
            RightIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='secondary'
            size='normal'
            LeftIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='secondary'
            size='large'
            RightIcon={<span className='icon'>+</span>}
          />
          <p>Disable</p>
          <CoreButton
            title='Default'
            disabled={true}
            onClick={() => console.log('clicked')}
            variation='secondary'
            size='small'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <p>collapsed</p>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='secondary'
            size='small'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='secondary'
            size='normal'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='secondary'
            size='large'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            disabled={true}
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='secondary'
            size='small'
            LeftIcon={<span className='icon'>+</span>}
          />
          <p>isLoading</p>

          <CoreButton
            collapsed={true}
            isLoading={true}
            title='Default'
            onClick={() => console.log('clicked')}
            variation='secondary'
            size='small'
            LeftIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            collapsed={true}
            isLoading={true}
            title='Default'
            onClick={() => console.log('clicked')}
            variation='secondary'
            size='normal'
            LeftIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            collapsed={true}
            isLoading={true}
            onClick={() => console.log('clicked')}
            variation='secondary'
            size='large'
          />
        </div>
        {/* outlineButtons */}
        <div
          style={{
            marginLeft: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <h5 style={{ margin: 0 }}>Variation: tertiary</h5>
          <h5>full Width</h5>
          <div style={{ width: '250px', marginBottom: '10px' }}>
            <CoreButton
              title='Default'
              onClick={() => console.log('clicked')}
              variation='tertiary'
              size='small'
              LeftIcon={<span className='icon'>+</span>}
              RightIcon={<span className='icon'>+</span>}
              fullWidth
            />
          </div>
          <p>size</p>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='tertiary'
            size='small'
            LeftIcon={<span className='icon'>+</span>}
            RightIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='tertiary'
            size='normal'
            LeftIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='tertiary'
            size='large'
            RightIcon={<span className='icon'>+</span>}
          />
          <p>Disable</p>
          <CoreButton
            title='Default'
            disabled={true}
            onClick={() => console.log('clicked')}
            variation='tertiary'
            size='small'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <p>collapsed</p>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='tertiary'
            size='small'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='tertiary'
            size='normal'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='tertiary'
            size='large'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            disabled={true}
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='tertiary'
            size='small'
            LeftIcon={<span className='icon'>+</span>}
          />
          <p>isLoading</p>
          <CoreButton
            collapsed={true}
            isLoading={true}
            title='Default'
            onClick={() => console.log('clicked')}
            variation='tertiary'
            size='small'
            LeftIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            collapsed={true}
            isLoading={true}
            title='Default'
            onClick={() => console.log('clicked')}
            variation='tertiary'
            size='normal'
            LeftIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            collapsed={true}
            isLoading={true}
            onClick={() => console.log('clicked')}
            variation='tertiary'
            size='large'
          />
        </div>
        <div
          style={{
            marginLeft: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <h5 style={{ margin: 0 }}>Variation: outline</h5>
          <h5>full Width</h5>
          <div style={{ width: '250px', marginBottom: '10px' }}>
            <CoreButton
              title='Default'
              onClick={() => console.log('clicked')}
              variation='outline'
              size='small'
              LeftIcon={<span className='icon'>+</span>}
              RightIcon={<span className='icon'>+</span>}
              fullWidth
            />
          </div>
          <p>size</p>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='outline'
            size='small'
            LeftIcon={<span className='icon'>+</span>}
            RightIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='outline'
            size='normal'
            LeftIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            onClick={() => console.log('clicked')}
            variation='outline'
            size='large'
            RightIcon={<span className='icon'>+</span>}
          />
          <p>Disable</p>
          <CoreButton
            title='Default'
            disabled={true}
            onClick={() => console.log('clicked')}
            variation='outline'
            size='small'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <p>collapsed</p>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='outline'
            size='small'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='outline'
            size='normal'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='outline'
            size='large'
            CollapsedIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            title='Default'
            disabled={true}
            collapsed={true}
            onClick={() => console.log('clicked')}
            variation='outline'
            size='small'
            RightIcon={<span className='icon'>+</span>}
          />
          <p>isLoading</p>

          <CoreButton
            collapsed={true}
            isLoading={true}
            title='Default'
            onClick={() => console.log('clicked')}
            variation='outline'
            size='small'
            RightIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            collapsed={true}
            isLoading={true}
            title='Default'
            onClick={() => console.log('clicked')}
            variation='outline'
            size='normal'
            LeftIcon={<span className='icon'>+</span>}
          />
          <div className='mb-1'></div>
          <CoreButton
            collapsed={true}
            isLoading={true}
            onClick={() => console.log('clicked')}
            variation='outline'
            size='large'
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div
            style={{
              marginLeft: '40px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginBottom: '40px'
            }}>
            <h5>fade - false</h5>
            <CoreStatusView status={TransactionStatusEnum.PENDING} fade={false} />
            <CoreStatusView status={TransactionStatusEnum.INITIAL} fade={false} />
            <CoreStatusView status={TransactionStatusEnum.SCHEDULED} fade={false} />
            <CoreStatusView status={TransactionStatusEnum.CANCELLED} fade={false} />
            <CoreStatusView status={TransactionStatusEnum.CANCELLED} fade={false} />
            <CoreStatusView status={TransactionStatusEnum.PROCESSED} fade={false} />
            <CoreStatusView status={TransactionStatusEnum.CONFIRMED} fade={false} />
          </div>
          <div
            style={{
              marginLeft: '40px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <h5>fade - true</h5>
            <CoreStatusView status={TransactionStatusEnum.PENDING} fade={true} />
            <CoreStatusView status={TransactionStatusEnum.INITIAL} fade={true} />
            <CoreStatusView status={TransactionStatusEnum.SCHEDULED} fade={true} />
            <CoreStatusView status={TransactionStatusEnum.CANCELLED} fade={true} />
            <CoreStatusView status={TransactionStatusEnum.CANCELLED} fade={true} />
            <CoreStatusView status={TransactionStatusEnum.PROCESSED} fade={true} />
            <CoreStatusView status={TransactionStatusEnum.CONFIRMED} fade={true} />
          </div>
        </div>
        <div
          style={{
            marginLeft: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <h5>Checkbox</h5>
          <CoreCheckbox
            type='check'
            value='check'
            label='type="check"(default)'
            checked={checkChecked}
            fontStyle={{ fontWeight: 'bold', fontSize: 11 }}
            onClick={setCheckChecked}
          />
          <CoreCheckbox
            type='check'
            value='check'
            label='type="check"(default)/disabled'
            disabled
            checked={true}
            fontStyle={{ fontWeight: 'bold', fontSize: 11 }}
            onClick={setCheckChecked}
          />
          <CoreCheckbox
            type='dash'
            value='dash'
            label='type="dash"'
            checked={dashChecked}
            onClick={setDashChecked}
            fontStyle={{ fontWeight: 'bold', fontSize: 11 }}
          />
          <CoreCheckbox
            type='dash'
            value='dash'
            label='type="dash"/disabled'
            disabled
            fontStyle={{ fontWeight: 'bold', fontSize: 11 }}
          />
          <CoreCheckbox
            type='circle'
            value='circle'
            label='type="circle"'
            checked={circleChecked}
            onClick={setCircleChecked}
            fontStyle={{ fontWeight: 'bold', fontSize: 11 }}
          />
          <CoreCheckbox
            type='circle'
            value='circle'
            label='type="circle"/disabled'
            disabled
            fontStyle={{ fontWeight: 'bold', fontSize: 11 }}
          />
        </div>
        <div
          style={{
            marginLeft: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <h5>Radio buttons</h5>
          <CoreRadioButton onClick={setRadioButtonValue} radioGroupe='demo' buttons={radioButtonsDemo} />
          <span style={{ marginTop: '30px', border: '1px solid #757575', padding: '10px', borderRadius: '8px' }}>
            Radio value: {radioButtonValue}
          </span>
        </div>
        <div
          style={{
            marginLeft: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <h5>Demo confirm email</h5>
          <CoreButton title='modal trigger' onClick={() => setOpen(!open)} variation='primary' size='small' />
        </div>
        <div
          style={{
            marginLeft: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <h5>Tabs</h5>
          <CoreTabs tabs={demoTabs} onChangeTab={setSelectTab} preselected='label3aasdasdasd2' />
          <h4> Focused tab - {selectTab}</h4>
        </div>
        <CoreMenu items={demoMenuitems} />
      </div>
    </SnowFallBackground>
  )
}

export default DemoStyleViewer

// Otp
