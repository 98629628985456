import { useContext, useEffect, useState } from 'react'

import ToastSuccess from '@images/icons/success-icon.svg?react'
import WelcomeIcon2 from '@images/logo-full.svg?react'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'
import { MerchantAccountTypeEnum } from 'mmfintech-commons-types'

import './welcomeContent2.scss'

function isSameDayAndMonth(createdOn) {
  const createdOnDate = new Date(createdOn)

  const today = new Date()

  return createdOnDate.getMonth() === today.getMonth() && createdOnDate.getDate() === today.getDate()
}

type TWelcomeContent2Props = {
  isSuccess2Fa: boolean
}

export const WelcomeContent2 = ({ isSuccess2Fa }: TWelcomeContent2Props) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, createdOn } = merchant || {}
  const { modalHide } = useContext(GlobalContext)

  if (accountType !== MerchantAccountTypeEnum.PROSPECT || !isSameDayAndMonth(createdOn)) {
    modalHide()
  }

  useEffect(() => {
    let timer

    if (isSuccess2Fa) {
      setShowSuccess(true)

      timer = setTimeout(() => {
        setShowSuccess(false)
      }, 2000)
    } else {
      setShowSuccess(false)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [isSuccess2Fa])

  return (
    <div className='welcome-step-2'>
      {isSuccess2Fa && (
        <div className='success-2fa'>
          {showSuccess && (
            <div className='success-2fa-content'>
              <ToastSuccess />
              <div className='success-2fa-text'>
                <span className='title-success'>
                  {tr('FRONTEND.WELCOME.MODAL.STEP_2.SUCCESS_1', 'Two-factor authentication method changed!')}
                </span>
                <span className='description-success'>
                  {tr(
                    'FRONTEND.WELCOME.MODAL.STEP_2.SUCCESS_2',
                    'This change will take effect during your next login.'
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      <div className='logo'>
        <WelcomeIcon2 />
      </div>
      <div className='title'>
        {isSuccess2Fa
          ? tr('FRONTEND.WELCOME.MODAL.STEP_2.TITLE_1', 'Great! Your account is now well protected!')
          : tr('FRONTEND.WELCOME.MODAL.STEP_2.TITLE_2', 'We are happy that you registered with us!')}
      </div>
      <p className='description'>
        {isSuccess2Fa
          ? tr(
              'FRONTEND.WELCOME.MODAL.STEP_2.DESCRIPTION_1',
              'The final step to easily start depositing, sending and exchanging money, is to complete the onboarding!'
            )
          : tr(
              'FRONTEND.WELCOME.MODAL.STEP_2.DESCRIPTION_2',
              'Lets complete the final onboarding step so you can start depositing, sending and exchanging money!'
            )}
      </p>
    </div>
  )
}
