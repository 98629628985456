import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import GoogleAuthenticator from '@components/GoogleAuthenticator'
import ResendIcon from '@images/icons/resend-icon.svg?react'
import { WelcomeModal } from '@views/dashboard/elements/WelcomeModal'

import { globalSettings, paths, useOtpQry } from 'mmfintech-backend-api'
import { GlobalContext, OtpContext, tr, useValidUntilTimer } from 'mmfintech-commons'
import { ChallengePurposeEnum, LoginStatusEnum, TwoFactorType, TwoFactorTypeEnum } from 'mmfintech-commons-types'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import CoreButton from '../CoreButton'
import OtpInput from '../OtpInput/OtpInput'
import './otp.scss'

import EmailVerifyIcon from '@images/icons/e-mail-verification-icon.png'

interface prepareTitle {
  twoFactorType: TwoFactorType
  userStatus: any
}

export const prepareTitle = ({ twoFactorType, userStatus }: prepareTitle) => {
  if (twoFactorType === 'SMS') {
    if (userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS Verification')
    }

    return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS Confirmation')
  }

  if (twoFactorType === TwoFactorTypeEnum.TOTP) {
    if (userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_TOTP', 'Google Authenticator verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_TOTP', 'Google Authenticator confirmation')
  }

  if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
    return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'E-mail Verification')
  }

  return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'E-mail Confirmation')
}

export const Otp = () => {
  const {
    code,
    codeSize,
    attempts,
    challenge,
    handleCodeChanged,
    handleSubmit,
    handleResend,
    verifyOtpError,
    verifyOtpFetching,
    resendOtpFetching,
    resendTimer
  } = useOtpQry()

  const timer = useValidUntilTimer()
  const { setOtpOnError, setOtpOnSuccess } = useContext(OtpContext)
  const { modalShow } = useContext(GlobalContext)
  const { pathname } = useLocation()

  const { sentTo, twoFactorType, totpSecretKeyUri, challengePurpose } = challenge || {}

  useEffect(() => {
    if (totpSecretKeyUri) {
      setOtpOnSuccess(() => (response: any) => {
        if (response && totpSecretKeyUri && pathname === paths.dashboard() && challengePurpose === 'CHANGE_2FA_TOTP') {
          setTimeout(() => {
            modalShow({
              header: (
                <span style={{ paddingRight: '5.4rem' }}>
                  {tr('FRONTEND.WELCOME.MODAL.HEADING', 'Welcome to Jeton!')}
                </span>
              ),
              options: {
                closeOnClickOutside: false,
                closeOnEscape: false,
                hideCloseIcon: true
              },
              content: <WelcomeModal currentStep={2} isSuccess2Fa={true} />
            })
          }, 0)
        }
      })
    }

    return () => {
      setOtpOnSuccess(null)
      setOtpOnError(null)
    }
  }, [totpSecretKeyUri])

  const internalSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    void handleSubmit()
  }

  return (
    <form className='otp-wrapper' noValidate onSubmit={internalSubmit}>
      {totpSecretKeyUri ? (
        <GoogleAuthenticator totpSecretKeyUri={totpSecretKeyUri} />
      ) : (
        <>
          <div style={{ marginTop: '-1rem' }}>
            <img src={EmailVerifyIcon} alt='email-verify-icon' />
          </div>
          <div data-test='otp-subtitle' className='subtitle'>
            {twoFactorType === TwoFactorTypeEnum.TOTP
              ? tr('FRONTEND.VERIFY_OTP.TOTP_TARGET_LABEL', 'Please, enter the verification code:')
              : tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', 'Please enter the verification code that we’ve sent')}{' '}
            <p data-test='otp-send-to'>{sentTo}</p>
          </div>
        </>
      )}

      <ErrorDisplay error={verifyOtpError} />

      {resendOtpFetching || verifyOtpFetching ? (
        <Spinner />
      ) : (
        <div className='content-wrapper'>
          <OtpInput
            codeLength={codeSize}
            onChange={value => handleCodeChanged(value)}
            disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
          />
          <div className='countdown'>{timer.formattedTime}</div>
          {challengePurpose !== ChallengePurposeEnum.CHANGE_2FA_TOTP && twoFactorType !== TwoFactorTypeEnum.TOTP && (
            <div>
              {attempts < globalSettings.otpMaxAttempts ? (
                <CoreButton
                  data-test='otp-re-send-button'
                  onClick={handleResend}
                  disabled={!resendTimer.expired}
                  LeftIcon={resendTimer.expired ? <ResendIcon /> : null}
                  type='button'
                  title={
                    resendTimer.expired
                      ? tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send')
                      : (resendTimer.remainingTime as string)
                  }
                  variation={'secondary'}
                  size='normal'
                />
              ) : (
                <div className=''>{tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')}</div>
              )}
            </div>
          )}
          <div className='continue-button'>
            <CoreButton
              data-test='otp-confirm-button'
              fullWidth
              title={tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
              disabled={code.length < codeSize || timer.expired || attempts >= globalSettings.otpMaxAttempts}
              size='normal'
              variation={'primary'}
            />
          </div>
        </div>
      )}
    </form>
  )
}
