import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import CoreButton from '@components/CoreButton'
import ChevronIcon from '@images/icons/chevron-right-red.svg?react'

import { paths } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import RecentTransactionsList from '../../transactions/RecentTransactionsList'
import './styled/transactionsSection.scss'

function TransactionsSection({ title = '', filter = {}, showPagination = false, forceReload = false }) {
  const history = useHistory()
  const [showButton, setShowButton] = useState<boolean>(false)

  return (
    <div className='transaction-section-wrapper'>
      <div className='transaction-section-header'>
        <h3 data-test='transaction-section-title'>
          {title || tr('FRONTEND.DASHBOARD.TRANSACTIONS_TITLE', 'Recent transactions')}
        </h3>
        {showButton && (
          <CoreButton
            title={tr('FRONTEND.DASHBOARD.TRANSACTIONS.BUTTON.VIEW_ALL', 'View all')}
            variation='secondary'
            size='normal'
            width='12.5rem'
            RightIcon={<ChevronIcon />}
            onClick={() => history.push(paths.banking.transactions.list())}
            data-test='transaction-view-all-button'
          />
        )}
      </div>
      <RecentTransactionsList
        filter={filter}
        showPagination={showPagination}
        forceReload={forceReload}
        showRedirectButton={setShowButton}
      />
    </div>
  )
}

export default TransactionsSection
