import { MenuItem } from 'components/CoreMenu'

import { paths } from 'mmfintech-backend-api'

import AccountsIcon from '@images/icons/menu-accounts-icon.svg'
import CreditCardIcon from '@images/icons/menu-credit-card.svg'
import MenuHoseIcon from '@images/icons/menu-icon-home.svg'
import RecipientIcon from '@images/icons/menu-recipients-icon.svg'
import TransactionIcon from '@images/icons/menu-transaction-icon.svg'

export const coreMenuItems: MenuItem[] = [
  {
    imageSrc: MenuHoseIcon,
    label: 'Overview',
    path: paths.dashboard(),
    localization: 'FRONTEND.NAVIGATION.DASHBOARD',
    toolTip: 'Overview'
  },
  {
    imageSrc: TransactionIcon,
    label: 'Transactions',
    path: paths.banking.transactions.list(),
    localization: 'FRONTEND.NAVIGATION.TRANSACTIONS',
    toolTip: 'Transactions'
  },
  {
    imageSrc: AccountsIcon,
    label: 'Accounts',
    path: paths.banking.accounts.list(),
    localization: 'FRONTEND.NAVIGATION.ACCOUNTS',
    toolTip: 'Accounts'
  },
  {
    imageSrc: RecipientIcon,
    label: 'Recipients',
    path: paths.recipients.list(),
    localization: 'FRONTEND.NAVIGATION.RECIPIENTS',
    toolTip: 'Recipients'
  },
  {
    imageSrc: CreditCardIcon,
    label: 'Cards',
    path: '/cards',
    localization: 'FRONTEND.NAVIGATION.CARDS',
    toolTip: 'Cards'
  }
]
