import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'

import CoreButton from '@components/CoreButton'
import AppstoreIcon from '@images/icons/appstore-app-icon.svg?react'
import CrossIcon from '@images/icons/cross-button-icon.svg?react'
import GooglePlayIcon from '@images/icons/googleplay-app-icon.svg?react'
import settings from '@settings'

import { configuration } from 'mmfintech-backend-api'
import { isValidObject, tr } from 'mmfintech-commons'

import './styled/downloadAppComponent.scss'

const DownloadAppComponent = () => {
  const [cookies, setCookie] = useCookies(['cookie.download.app']) as any

  const handleCloseDownloadApp = () => {
    setCookie(
      'cookie.download.app',
      JSON.stringify({
        downloadAppCookie: true
      }),
      {
        days: 365,
        maxAge: 31536000,
        domain: configuration.isLocal() ? null : settings.cookieDomain
      }
    )
  }
  return (
    !isValidObject(cookies['cookie.download.app']) && (
      <div data-test='test-download-app-component' className='download-app-component-container'>
        <div className='download-app-header'>
          <div>
            <span data-test='download-app-title'>{tr('FRONTEND.DASHBOARD.DOWNLOAD_APP', 'Download Jeton app')}</span>
            {/* <p data-test='download-app-label'>{tr('FRONTEND.DASHBOARD.USE_APP', 'Use Jeton app to')}</p> */}
          </div>
          <CoreButton
            variation='secondary'
            onClick={handleCloseDownloadApp}
            size='small'
            collapsed
            CollapsedIcon={<CrossIcon />}
            data-test='download-app-button'
          />
        </div>
        <div className='download-app-icons'>
          <div className='download-app-link'>
            <Link
              to={{ pathname: 'https://apps.apple.com/us/app/jeton/id6499320378' }}
              target='_blank'
              data-test='download-app-appstore'>
              <AppstoreIcon data-test='download-app-appstore-icon' />
            </Link>
          </div>
          <div className='download-app-link'>
            <Link
              to={{ pathname: 'https://play.google.com/store/apps/details?id=com.jeton.app&pli=1' }}
              target='_blank'
              data-test='download-app-googlePlay'>
              <GooglePlayIcon data-test='download-app-googleplay-icon' />
            </Link>
          </div>
        </div>
      </div>
    )
  )
}

export default DownloadAppComponent
