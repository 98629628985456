import { memo, useContext } from 'react'

import isEqual from 'lodash.isequal'

import { GlobalContext, tr } from 'mmfintech-commons'

import CoreButton from '../CoreButton'
import './confirmationModalDelete.scss'

interface IConfirmationModal {
  title?: string
  question: string | object
  onConfirm?: () => void
  confirmCaption: string
  onReject?: () => void
  rejectCaption?: string
}

function ConfirmationModal({
  question,
  onConfirm,
  confirmCaption = tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm'),
  onReject,
  rejectCaption = tr('FRONTEND.BUTTONS.REJECT', 'Reject')
}: IConfirmationModal) {
  const { modalHide } = useContext(GlobalContext)
  const handleConfirm = () => {
    modalHide()
    onConfirm && onConfirm()
  }

  return (
    <div className='confirmation-delete-profile-container' data-test='confirmation-dialog-modal'>
      <div data-test={`confirmation-modal-${question}`} className='confirmation-delete-profile-content'>
        {question}
      </div>
      <div className='confirmation-delete-profile-footer'>
        <CoreButton
          onClick={handleConfirm}
          data-test='confirmation-button-confirm'
          fullWidth
          size='normal'
          title={confirmCaption || tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
        />
        {onReject && (
          <CoreButton
            onClick={onReject}
            data-test='button-reject'
            className='button-reject'
            size='normal'
            variation='secondary'
            title={rejectCaption || tr('FRONTEND.BUTTONS.REJECT', 'Reject')}
          />
        )}
      </div>
    </div>
  )
}

export default memo(ConfirmationModal, (prevProps, nextProps) => isEqual(prevProps, nextProps))
