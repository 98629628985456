import { useContext } from 'react'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

import CoreButton from '@components/CoreButton'

import { paths, useTerminateCardMutation } from 'mmfintech-backend-api'
import { OtpContext, tr, translateError } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

interface TerminateCardProps {
  prepaidCardId?: string 
}

const TerminateCard = ({ prepaidCardId }: TerminateCardProps) => {
  const history = useHistory()

  const { setOtpOnSuccess } = useContext(OtpContext)

  const [terminateCard, { isLoading: isTerminating, error: terminationError }] = useTerminateCardMutation()

  const handleTerminateCard = async () => {
    if (prepaidCardId) {
      setOtpOnSuccess(() => result => {
        if (result) {
          toast.success(tr('FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.MODAL.SUCCESS', 'Card terminated successfully!'))
          return history.push(paths.dashboard())
        }
      })
      try {
        const result = await terminateCard({
          prepaidCardId
        }).unwrap()
        if (!result.challenge) {
          toast.success(tr('FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.MODAL.SUCCESS', 'Card terminated successfully!'))
          return history.push(paths.dashboard())
        }
      } catch (error) {
        const message = translateError(error)
        if (message) {
          toast.remove()
          toast.error(message)
        }
      }
    }
  }

  return (
    <div className='terminate-card-wrapper'>
      <div className='terminate-card-text'>
        {tr(
          'FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.MODAL.TEXT',
          'This action is irreversible and will prevent the future use of the card. If you have available balance, it will be automatically transferred to an account with the same currency. No fees will be applied for this transfer. Do you wish to proceed?'
        )}
      </div>
      <ErrorDisplay error={terminationError} />
      <CoreButton
        fullWidth
        type='button'
        variation='primary'
        size='normal'
        isLoading={isTerminating}
        title={tr('FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.MODAL.TERMINATE_BUTTON', 'Terminate card')}
        onClick={() => handleTerminateCard()}
      />
    </div>
  )
}

export default TerminateCard
