import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import Pagination from '@components/CorePagination'
import CoreTabs from '@components/CoreTabs'
import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'
import settings from '@settings'
import NoTransactionsMessage from '@views/transactions/NoTransactionsMessage'
import TransactionsTable from '@views/transactions/elements/TransactionsTable'

import {
  useLazyGetTransactionsQuery,
  useMerchantAccounts,
  useSelectedAccount,
  useTransactionColumns
} from 'mmfintech-backend-api'
import { isValidArray, tr, usePaginationQuery } from 'mmfintech-commons'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import AccountDetails from './elements/AccountDetails'
import CarouselCards from './elements/CarouselCards'
import './styled/accounts.scss'

import NoTransactionsImage from '@images/icons/no-recent-transactions.png'

function Accounts() {
  const columns = useTransactionColumns({
    cookieDomain: settings.cookieDomain,
    enableCustomerEmail: false,
    enableFailReason: false
  })

  const [fetchTransactions, { transactions, transactionsError, transactionsFetching }] = useLazyGetTransactionsQuery({
    selectFromResult: ({ data, error, isFetching }) => ({
      transactions: isValidArray(data?.content) ? data.content : [],
      transactionsError: error,
      transactionsFetching: isFetching
    })
  })

  const [selectedTab, setSelectedTab] = useState<string>('Transaction')

  const { accounts: paymentAccounts } = useMerchantAccounts()
  const { selectedAccount, selectedAccountId, setSelectedAccount } = useSelectedAccount()

  const isLarge = useMatchMedia({ breakpoint: breakpoints.md, prefix: 'min' })

  // useEffect(() => {
  //   setNonCardAccounts(paymentAccounts.filter((account:any) => account && !account.prepaidCardAccount))
  // },[paymentAccounts])

  useEffect(() => {
    
    if (paymentAccounts && paymentAccounts.length > 0 && !selectedAccount) {
      setSelectedAccount(paymentAccounts[0])
    }
  }, [selectedAccount, paymentAccounts])

  const pagination = usePaginationQuery({
    rowsPerPage: 5,
    reload: async (params, onSuccess = null) => {
      try {
        const response = await fetchTransactions({
          ...params,
          accountId: selectedAccountId,
          sort: 'reqTime-desc,id-desc'
        }).unwrap()
        if (response) {
          onSuccess(response)
        }
      } catch (_err) {}
    }
  })

  useEffect(() => {
    if (paymentAccounts && paymentAccounts.length > 0) {
      setSelectedAccount(paymentAccounts.find(account => account?.id == selectedAccountId))
    }
  }, [paymentAccounts])

  useEffect(() => {
    pagination.reload()
  }, [selectedAccount])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <div data-test='accounts-container-test' className='accounts-container'>
      {paymentAccounts && (
        <div className='account-header-container'>
          <div className='account-header-carousel-wrapper'>
            <CarouselCards
              accounts={paymentAccounts}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
            />
          </div>
        </div>
      )}
      <div data-test='account-content-container' className='account-content-container'>
        <div className='account-content-header-wrapper'>
          <div data-test='account-content-header' className='account-content-header'>
            {selectedAccount && <span>{selectedAccount.name}</span>}
          </div>
          <div data-test='account-content-header-tabs' className='account-content-header-tabs'>
            <CoreTabs
              onChangeTab={setSelectedTab}
              tabs={[
                {
                  label: tr('FRONTEND.ACCOUNTS.TRANSACTION_LABEL', 'Transaction'),
                  value: 'Transaction'
                },
                {
                  label: tr('FRONTEND.ACCOUNTS.ACCOUNT_DETAILS', 'Account details'),
                  value: 'Account details'
                }
              ]}
              preselected={selectedTab}
            />
          </div>
        </div>
        <div data-test='account-content-wrapper' className='account-content-wrapper'>
          {selectedTab == 'Transaction' && (
            <div data-test='account-content-table' className='account-content-table'>
              {transactionsFetching ? (
                <Spinner />
              ) : transactionsError ? (
                <ErrorDisplay error={transactionsError} />
              ) : isValidArray(transactions) ? (
                <>
                  <TransactionsTable columns={columns} transactions={transactions} />
                  <div data-test='pagination' className='pagination-buttons-container'>
                    <Pagination {...pagination.register()} pageRange={isLarge ? 3 : 1} />
                  </div>
                </>
              ) : (
                <NoTransactionsMessage iconLink={NoTransactionsImage} />
              )}
            </div>
          )}
          {selectedAccount && selectedTab == 'Account details' && (
            <div data-test='account-content-detail-tab' className='account-content-detail-tab'>
              <AccountDetails selectedAccount={selectedAccount} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Accounts
