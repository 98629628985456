import { Dispatch, KeyboardEvent, SetStateAction, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import PhoneInput from 'react-phone-input-2'

import ClearIcon from '@images/icons/close.svg?react'
import ClosedEyeIcon from '@images/icons/eye-close.svg?react'
import OpenedEyeIcon from '@images/icons/eye.svg?react'
import cn from 'classnames'

import './coreInput.scss'

// import LeftArrow from '@images/icons/left-arrow-button.svg?react'
// import RightArrow from '@images/icons/right-arrow-button.svg?react'

interface InputProps {
  className?: string
  error?: any
  LeftIcon?: JSX.Element
  RightIcon?: JSX.Element
  label?: string
  name?: string
  onChange?: any
  hint?: string
  value?: string
  required?: boolean
  type?: 'text' | 'password' | 'phone' | 'date' | 'email' | 'number'
  clearable?: boolean
  setFocus?: Dispatch<SetStateAction<boolean>>
  focused?: boolean
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  disabled?: boolean
  onClick?: () => void
  focusedInput?: (name) => void
  passwordLock?: (boolean) => void
  validation?: (string) => string
  rangeDates?: boolean
  startDate?: any
  endDate?: any
  minDate?: any
  maxDate?: any
  selectsStart?: boolean
  selectsEnd?: boolean
  placeholder?: string
  maxLength?: number
  autoComplete?: 'off' | 'on'
  size?: 'small' | 'normal' | 'large'
  blockBlur?: boolean
  readOnly?: boolean
}

const CoreInput = ({
  className = '',
  error,
  LeftIcon,
  RightIcon,
  label,
  name,
  onChange,
  hint,
  value,
  required,
  type = 'text',
  clearable,
  setFocus,
  focused,
  onKeyDown,
  disabled,
  minDate,
  rangeDates,
  startDate,
  endDate,
  selectsStart,
  selectsEnd,
  maxDate,
  placeholder = '',
  focusedInput,
  passwordLock,
  validation,
  autoComplete = 'off',
  size = 'normal',
  blockBlur = false,
  ...rest
}: InputProps) => {
  const inputRef = useRef(null)

  //to prevent showing two different eye for password field
  const isOldFireFox = window.navigator.userAgent.includes('Firefox/12')

  const [inputValue, setInputValue] = useState(value)
  const [isFocused, setIsFocused] = useState(false)
  const [inputType, setInputType] = useState(type)
  const now = new Date().getUTCFullYear()
  const years = Array(now - (now - 110))
    .fill('')
    .map((_, idx) => now - idx)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const getYear = data => {
    return new Date(data).getUTCFullYear()
  }
  const getMonth = data => {
    return new Date(data).getMonth()
  }

  if (selectsEnd) {
  }
  useEffect(() => {
    if (!value) {
      setInputValue(null)
    }
    if (value) {
      setInputValue(validation ? validation(value) : value)
    }
  }, [value])

  useEffect(() => {
    // moves caret to the end of the input as the default focus sets it at the start
    if (inputRef.current && inputType !== 'email' && inputType !== 'number') {
      inputRef.current.selectionStart = inputRef.current.value.length
      inputRef.current.selectionEnd = inputRef.current.value.length
    }
  }, [inputType])

  let timeoutId = null

  useEffect(() => {
    if (focused) {
      setIsFocused(true)
      inputRef.current && inputRef.current?.focus()
    } else {
      setIsFocused(false)
      focusedInput && focusedInput(undefined)
      inputRef.current && inputRef.current?.blur()
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [focused, blockBlur])

  useEffect(() => {
    if (passwordLock) {
      inputType === 'password' ? passwordLock(true) : passwordLock(false)
    }
  }, [inputType])

  return (
    <div
      onClick={() => {
        setIsFocused(true)
        setFocus && setFocus(true)
        inputRef.current && inputRef.current?.focus()
      }}
      className={cn(className, 'core-input-wrapper')}>
      <div className={cn('core-input-section', { focused: isFocused, errored: !!error }, size)}>
        {LeftIcon && <div className='core-input-left-icon'>{LeftIcon}</div>}
        <div
          className={cn('core-input-inner-section', {
            'inner-section-phone': type == 'phone',
            'inner-section-final-state': isFocused || !!inputValue
          })}>
          {type == 'phone' ? (
            <PhoneInput
              data-test='phone-input'
              placeholder=''
              onFocus={() => {
                setIsFocused(true)
                focusedInput && focusedInput(name)
                setFocus && setFocus(true)
              }}
              onBlur={() => {
                timeoutId = setTimeout(() => {
                  setIsFocused(false)
                  !blockBlur && setFocus && setFocus(false)
                }, 200)
              }}
              onKeyDown={e => {
                if ((isNaN(Number(e.key)) && e.key !== 'Backspace') || e.key == ' ') {
                  return e.preventDefault()
                }
              }}
              onChange={(value, data: any) => {
                const dialCode = data?.dialCode || ''
                const currentPhoneCode = value.slice(0, dialCode.length)
                if (dialCode !== currentPhoneCode) {
                  setInputValue(dialCode)
                  onChange && onChange(name, dialCode)
                } else {
                  setInputValue(value)
                  onChange && onChange(name, value)
                }
              }}
              value={inputValue || ''}
              disabled={disabled}
              enableSearch
              disableSearchIcon
              searchPlaceholder=''
              inputProps={{ id: name, name, required }}
            />
          ) : type == 'date' ? (
            <div className='core-input-date-picker-wrapper'>
              {!rangeDates ? (
                <DatePicker
                  data-test='date-pick-input'
                  selected={value ? new Date(value) : null}
                  renderCustomHeader={({ date, changeYear, changeMonth }) => (
                    <div className='calendar-header-wrapper'>
                      <select
                        className='calendar-select-input'
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value as any)}>
                        {years.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        className='calendar-select-input'
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                        {months.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  calendarStartDay={1}
                  onChange={date => onChange && date && onChange(name, date)}
                  calendarClassName='calendar-wrapper-classname'
                  minDate={minDate}
                  onBlur={() => {
                    timeoutId = setTimeout(() => {
                      if (document.activeElement !== inputRef.current) {
                        setIsFocused(false)
                        !blockBlur && setFocus && setFocus(false)
                      }
                    }, 200)
                  }}
                  {...rest}
                  customInput={
                    <input
                      className={cn('core-input-field', { errored: !!error })}
                      onChange={e => {
                        setInputValue(e.target.value)
                        onChange && onChange(name, e.target.value, e)
                      }}
                      value={inputValue || ''}
                      onFocus={() => {
                        if (document.activeElement === inputRef.current) {
                          setIsFocused(true)
                          focusedInput && focusedInput(name)
                          setFocus && setFocus(true)
                        }
                      }}
                      type={'text'}
                      disabled={disabled}
                      autoComplete='off'
                      ref={inputRef}
                      required={required}
                      name={name}
                      onKeyDown={onKeyDown}
                    />
                  }
                />
              ) : (
                <DatePicker
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth
                    // decreaseMonth,
                    // increaseMonth,
                    // prevMonthButtonDisabled,
                    // nextMonthButtonDisabled
                  }) => (
                    <div className='calendar-header-wrapper'>
                      {/* <button
                        className='calendar-arrow-button'
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}>
                        <LeftArrow />
                      </button> */}
                      <select
                        className='calendar-select-input'
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value as any)}>
                        {years.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        className='calendar-select-input'
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) => {
                          changeMonth(months.indexOf(value))
                        }}>
                        {months.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      {/* 
                      <button
                        className='calendar-arrow-button'
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}>
                        <RightArrow />
                      </button> */}
                    </div>
                  )}
                  data-test='date-pick-input'
                  selected={inputValue ? new Date(inputValue) : null}
                  onChange={date => {
                    setInputValue(date[0])
                    onChange && date && onChange(name, date)
                  }}
                  startDate={startDate ? new Date(startDate) : null}
                  endDate={endDate ? new Date(endDate) : null}
                  selectsStart={selectsStart}
                  minDate={minDate}
                  maxDate={maxDate}
                  selectsEnd={selectsEnd}
                  calendarClassName={'calendar-wrapper-classname'}
                  onBlur={() => {
                    timeoutId = setTimeout(() => {
                      if (document.activeElement !== inputRef.current) {
                        setIsFocused(false)
                        !blockBlur && setFocus && setFocus(false)
                      }
                    }, 200)
                  }}
                  {...rest}
                  customInput={
                    <input
                      className={cn('core-input-field', { errored: !!error })}
                      onChange={e => {
                        setInputValue(e.target.value)
                        onChange && onChange(name, e.target.value, e)
                      }}
                      value={inputValue as string}
                      onFocus={() => {
                        if (document.activeElement === inputRef.current) {
                          setIsFocused(true)
                          focusedInput && focusedInput(name)
                          setFocus && setFocus(true)
                        }
                      }}
                      type={'text'}
                      disabled={disabled}
                      ref={inputRef}
                      required={required}
                      name={name}
                      onKeyDown={onKeyDown}
                      autoComplete='off'
                      {...rest}
                    />
                  }
                />
              )}
            </div>
          ) : (
            <input
              data-test='base-input'
              className={cn('core-input-field', { errored: !!error })}
              onChange={e => {
                setInputValue(e.target.value)
                onChange && onChange(name, e.target.value, e)
              }}
              value={inputValue || ''}
              onFocus={() => {
                if (document.activeElement === inputRef.current) {
                  setIsFocused(true)
                  focusedInput && focusedInput(name)
                  setFocus && setFocus(true)
                }
              }}
              onBlur={() => {
                timeoutId = setTimeout(() => {
                  if (document.activeElement !== inputRef.current) {
                    setIsFocused(false)
                    !blockBlur && setFocus && setFocus(false)
                  }
                }, 200)
              }}
              type={inputType}
              disabled={disabled}
              ref={inputRef}
              required={required}
              name={name}
              onKeyDown={onKeyDown}
              placeholder={isFocused ? placeholder : ''}
              autoComplete={autoComplete}
              {...rest}
            />
          )}
          <span
            className={cn('core-input-moving-label', `${!isFocused && !inputValue ? 'initial-state' : 'final-state'}`)}>
            {label}
          </span>
        </div>
        <div className='core-input-icons-section'>
          {clearable && isFocused && inputValue && (
            <ClearIcon
              onClick={() => {
                setInputValue('')
                onChange && onChange(name, '')
                inputRef.current && inputRef.current.focus()
              }}
            />
          )}
          {RightIcon ||
            (type == 'password' && !isOldFireFox ? (
              inputType == 'password' ? (
                <OpenedEyeIcon
                  onClick={() => {
                    setInputType('text')
                    inputRef && inputRef.current.focus()
                  }}
                />
              ) : (
                <ClosedEyeIcon
                  onClick={() => {
                    setInputType('password')
                    inputRef && inputRef.current.focus()
                  }}
                />
              )
            ) : (
              <></>
            ))}
        </div>
      </div>
      {error && (
        <p data-test='core-input-error' className='core-input-error caption mt-1 pl-4'>
          {error}
        </p>
      )}
      {hint && !error && (
        <p data-test='core-input-hint' className='caption core-input-hint mt-1 pl-4'>
          {hint}
        </p>
      )}
    </div>
  )
}

export default CoreInput
