import { useCookies } from 'react-cookie'

import CoreButton from '@components/CoreButton'
import CrossIcon from '@images/icons/cross-button-icon.svg?react'
import settings from '@settings'

import { configuration } from 'mmfintech-backend-api'
import { isValidObject, tr } from 'mmfintech-commons'

import './styled/guideline.scss'

export interface IGuidelineItem {
  title: string
  description: string
}
const guideLineItems = () => {
  return [
    {
      title: tr('FRONTEND.DASHBOARD.DEPOSIT_MONEY.TITLE', 'Deposit money'),
      description: tr('FRONTEND.DASHBOARD.DEPOSIT_MONEY.DESCRIPTION', 'Deposit money to your account')
    },
    {
      title: tr('FRONTEND.DASHBOARD.PAYMENTS.TITLE', 'Receive payments'),
      description: tr('FRONTEND.DASHBOARD.PAYMENTS.DESCRIPTION', 'Receive international payments')
    },
    {
      title: tr('FRONTEND.DASHBOARD.SEND.TITLE', 'Send payments'),
      description: tr('FRONTEND.DASHBOARD.SEND.DESCRIPTION', 'Send international payments')
    }
  ]
}

const Guideline = () => {
  const [cookies, setCookie] = useCookies(['cookie.guideline']) as any

  const handleCloseGuideline = () => {
    setCookie(
      'cookie.guideline',
      JSON.stringify({
        downloadAppCookie: true
      }),
      {
        days: 365,
        maxAge: 31536000,
        domain: configuration.isLocal() ? null : settings.cookieDomain
      }
    )
  }

  return (
    !isValidObject(cookies['cookie.guideline']) && (
      <div data-test='test-guideline-container' className='guideline-modal-container'>
        <div className='tab-container'>
          <div className='tab-header'>
            <span data-test='guideline-title'>
              {tr('FRONTEND.DASHBOARD.GUIDELINE.TITLE', 'How to start with Jeton portal?')}
            </span>
            <CoreButton
              variation='secondary'
              data-test='guideline-close-button'
              size='small'
              collapsed
              onClick={handleCloseGuideline}
              CollapsedIcon={<CrossIcon />}
            />
          </div>
          <div className='tab-content-container'>
            {guideLineItems().map((item, index) => {
              const { title, description } = item
              return (
                <div key={index} data-test={`test-guideline-${index}`} className='tab-content-wrapper'>
                  <div className='tab-counter'>
                    <span data-test={`guideline-number-${index}`}>{index + 1}</span>
                  </div>
                  <div className='tab-content'>
                    <span data-test={`guideline-title-${title}`}>{title}</span>
                    <p data-test={`guideline-description-${description}`}>{description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  )
}

export default Guideline
