import { ToastBar, Toaster, toast } from 'react-hot-toast'

import ToastClose from '@images/icons/close-toast.svg?react'
import ToastError from '@images/icons/error-icon.svg?react'
import ToastSuccess from '@images/icons/success-icon.svg?react'
import colors from '@scss/variables/_colors.module.scss'

import './coreToaster.scss'

function CoreToaster(): JSX.Element {
  return (
    <Toaster
      gutter={10}
      position='top-right'
      containerStyle={{
        top: '10rem',
        zIndex: '10010'
      }}
      toastOptions={{
        duration: 2000,
        success: {
          className: 'toast-success',
          style: {
            backgroundColor: colors.toastSuccess,
            color: colors.black
          },
          icon: <ToastSuccess />
        },
        error: {
          style: {
            backgroundColor: colors.toastError,
            color: colors.orange900
          },
          icon: <ToastError />
        }
      }}>
      {t => {
        return (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <div data-test={`toaster-${message}`} className='toast-content-wrapper'>
                <div data-test={`toaster-icon-${message}`} className='icon'>
                  {icon}
                </div>
                <div data-test={`toaster-content-${message}`} className='content'>
                  <span className='caption'>{message}</span>
                </div>
                <div
                  data-test={`toaster-close-button-${message}`}
                  onClick={() => toast.dismiss(t.id)}
                  className='close-icon'>
                  <ToastClose />
                </div>
              </div>
            )}
          </ToastBar>
        )
      }}
    </Toaster>
  )
}
export default CoreToaster
