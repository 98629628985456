import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import CoreButton from '@components/CoreButton'
import CoreStepper from '@components/CoreStepper'
import useOnboarding from '@views/onboarding/useOnboarding'

import { resetEmailVerification, useChangeTwoFactorMutation } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { GoogleAuthenticatorInfo } from './GoogleAuthenticatorInfo'
import { WelcomeContent2 } from './WelcomeContent2'
import './welcomeModal.scss'

type TWelcomeModalProps = {
  currentStep?: number
  isSuccess2Fa?: boolean
}

export const WelcomeModal = ({ currentStep, isSuccess2Fa }: TWelcomeModalProps) => {
  const [change2fa, { error: change2faError }] = useChangeTwoFactorMutation()
  const { startOrContinueOnboarding } = useOnboarding()
  const [step, setStep] = useState(currentStep || 1)
  const dispatch = useDispatch()

  const handleSubmit = e => {
    if (e) e.preventDefault()

    change2fa({
      twoFactor: 'TOTP'
    })
  }

  const onResetEmailVerification = () => {
    dispatch(resetEmailVerification())
  }

  useEffect(() => {
    return () => {
      onResetEmailVerification()
    }
  }, [])

  return (
    <div className='welcome-wrapper'>
      <div className='welcome-container' data-test='welcome-modal'>
        <CoreStepper currentStep={step} totalSteps={2} />

        {step === 1 ? <GoogleAuthenticatorInfo /> : <WelcomeContent2 isSuccess2Fa={isSuccess2Fa} />}

        <ErrorDisplay error={change2faError} />

        <div className='footer-buttons'>
          {step === 1 ? (
            <>
              <CoreButton
                title={tr('FRONTEND.WELCOME.MODAL.SET_UP', 'Enable now')}
                onClick={handleSubmit}
                size='large'
                fullWidth
              />
              <CoreButton
                variation='tertiary'
                title={tr('FRONTEND.WELCOME.MODAL.SKIP_FOR_NOW', 'Skip for now')}
                onClick={() => setStep(2)}
                size='large'
              />
            </>
          ) : (
            <>
              <CoreButton
                title={tr('FRONTEND.WELCOME.MODAL.START_ONBOARDING', 'Get Started')}
                onClick={startOrContinueOnboarding}
                size='large'
                fullWidth
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
