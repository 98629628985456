import { useCallback, useState } from 'react'

import { BaseQueryApi } from '@reduxjs/toolkit/dist/query'

import { BackendErrorResponse, ErrorObject, extendedQuery } from 'mmfintech-backend-api'

import { store } from '../store'

interface useDownloadFileProps {
  endpointName: string
}

type DownloadMutationResult = [
  (args: DownloadArgs) => Promise<Blob>,
  {
    isLoading: boolean
    error: BackendErrorResponse | null
    response: any | null
  }
]

interface DownloadArgs {
  url: string
  method: string
  body?: Record<string, unknown>
}

export const useDownloadFileMutation = ({ endpointName }: useDownloadFileProps): DownloadMutationResult => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<BackendErrorResponse | null>(null)
  const [response, setResponse] = useState<string | ArrayBuffer | ArrayBufferView | Blob>(null)

  const controller = new AbortController()
  const signal = controller.signal
  const constructedApi: BaseQueryApi = {
    getState: () => store.getState(),
    dispatch: action => store.dispatch(action),
    type: 'mutation',
    abort: (reason?: string) => controller.abort(reason),
    endpoint: endpointName,
    signal,
    extra: () => {}
  }

  const handleDownload = useCallback(
    async (args: DownloadArgs) => {
      setIsLoading(true)
      setError(null)
      try {
        const res = await extendedQuery(
          {
            ...args,
            responseHandler: r => r.blob()
          },
          constructedApi,
          {}
        )
        if (res?.error) {
          if (res?.error.hasOwnProperty('cause')) {
            const { cause } = res?.error as unknown as ErrorObject
            const find = Array.isArray(cause) && cause.find(err => err.field === 'period')
            if (find) {
              setError({ errorKey: find.cause, error: 'Time period is too long' } as any)
            }
          } else {
            setError(res.error)
          }
        }
        if (res?.data) {
          setResponse(res.data as Blob)
          return res.data as Blob
        }
        return null
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [constructedApi]
  )

  return [
    handleDownload,
    {
      isLoading,
      error,
      response
    }
  ]
}
