import { useContext, useEffect, useState } from 'react'

import CardPodium from '@components/CardPodium'
import RequestNewCard from '@components/CardSettings/modals/RequestNewCard'
import CoreButton from '@components/CoreButton'
import HelpSection from '@components/HelpSection'

import { useGetAllCardsQuery } from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'
import { Spinner } from 'mmfintech-portal-commons'

import './card.scss'
import InactiveCard from './inactiveCard'
import MissingCard from './missingCard'
import CardTransactions from './transactions/Transactions'

const Card = () => {
  const { data: prepaidCards, isLoading } = useGetAllCardsQuery(null)
  const { modalShow } = useContext(GlobalContext)
  const [selectedCardType, setSelectedCardType] = useState<'virtual' | 'physical'>('physical')
  const [selectedCard, setSelectedCard] = useState(null)

  const handlePhysicalCardReissue = () => {
    modalShow({
      header: tr('FRONTEND.CARDS.SETTINGS.REQUEST_CARD.TITLE', 'I did not receive my card'),
      content: <RequestNewCard prepaidCardId={selectedCard?.id} />,
      options: { closeOnClickOutside: false }
    })
  }

  useEffect(() => {
    if (isValidArray(prepaidCards)) {
      const hasVirtual = prepaidCards.find(card => !!card.isVirtual)
      const hasPhysical = prepaidCards.find(card => !card.isVirtual)

      if (hasVirtual && !hasPhysical) {
        setSelectedCardType('virtual')
      }
    }
  }, [prepaidCards])

  return (
    <div className='cards-screen-wrapper'>
      {isLoading ? (
        <Spinner />
      ) : isValidArray(prepaidCards) ? (
        <div className='cards-screen-container'>
          <div className='cards-screen-left-section'>
            <CardPodium
              prepaidCards={prepaidCards}
              selectedTab={selectedCardType}
              setSelectedTab={setSelectedCardType}
              setSelectedCard={setSelectedCard}
            />
            {!!selectedCard ? (
              selectedCard?.status === 'NOT_ACTIVATED' ? (
                <div className='cards-screen-non-active-section'>
                  <div className='cards-screen-non-active-description'>
                    <p className='title'>{tr('FRONTEND.CARDS.NOTES.NOT_ACTIVATED.TITLE', 'Your card is on its way')}</p>
                    <p className='subtitle'>
                      {tr('FRONTEND.CARDS.NOTES.NOT_ACTIVATED.SUBTITLE', 'It should arrive within 1-2 weeks.')}
                    </p>
                  </div>
                  {selectedCard?.hasDeliveryExpired && (
                    <CoreButton
                      className='reissue-button'
                      size='normal'
                      title={tr('FRONTEND.CARDS.CARD_BALANCE.REISSUE', 'I did not receive my card')}
                      variation='primary'
                      fullWidth
                      onClick={handlePhysicalCardReissue}
                    />
                  )}
                </div>
              ) : (
                <CardTransactions accountId={selectedCard?.accountId} cardType={selectedCardType} />
              )
            ) : (
              <InactiveCard cardType={selectedCardType} />
            )}
          </div>
          <div className='cards-screen-right-section'>
            <HelpSection />
          </div>
        </div>
      ) : (
        <MissingCard />
      )}
    </div>
  )
}

export default Card
