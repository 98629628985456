import { FC } from 'react'

import StepperDivider from '@images/icons/stepper-divider.svg?react'

import './coreStepper.scss'

interface ICoreStepperProps {
  currentStep: number
  totalSteps: number
  title?: string
}

const CoreStepper: FC<ICoreStepperProps> = ({ currentStep, totalSteps, title }) => {
  return (
    <div className='stepper-wrapper'>
      {title && (
        <>
          <span data-test={`stepper-title-${title}`} className='details'>
            {title}
          </span>
          <StepperDivider />
        </>
      )}
      <span data-test={`stepper-step-${currentStep}`} className='steps'>
        {currentStep}/{totalSteps}
      </span>
    </div>
  )
}

export default CoreStepper
