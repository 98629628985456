import CoreButton from '@components/CoreButton'

import { tr } from 'mmfintech-commons'

import './notOnboardedModal.scss'

export const NotOnboardedModal = ({ onClose }) => {

  return (
    <div className='not-onboarded-wrapper'>
      <div className='not-onboarded-text'>
        {tr(
          'FRONTEND.CARDS.NOT_ONBOARDED.MODAL.TEXT',
          'You need to complete the onboarding process and get verified, before you can issue a card.'
        )}
      </div>
      <CoreButton
        fullWidth
        type='button'
        variation='primary'
        size='normal'
        title={tr('FRONTEND.CARDS.NOT_ONBOARDED.MODAL.OK', 'OK')}
        onClick={onClose}
      />
    </div>
  )
}
