import { useContext } from 'react'

import CoreButton from '@components/CoreButton'
import TransactionsExportDocumentIcon from '@images/icons/transactions-export-details-icon.svg?react'

import { useMerchantAccounts } from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'

import ExportTransactionFiles from '../ExportTransactionFiles'
import '../styled/transactionExploreButtons.scss'

export interface TransactionsExportButtonsInterface {
  accountId: number
  from: Date
  to: Date
}

export function TransactionsExportButtons({ accountId, from, to }: TransactionsExportButtonsInterface) {
  const { modalShow } = useContext(GlobalContext)
  const { accounts: paymentAccounts } = useMerchantAccounts()

  const getAccount = () => {
    if (isValidArray(paymentAccounts)) {
      return paymentAccounts.find(account => account.id === accountId)
    }
  }

  const handleBalanceStatementClick = () => {
    modalShow({
      header: tr('FRONTEND.STATEMENT.EXPORT.TITLE', 'Balance Statement Period'),
      options: { size: 'medium', closeOnClickOutside: false, closeOnEscape: false, overflow: 'visible' },
      content: (
        <ExportTransactionFiles
          exportType={'statement'}
          account={getAccount()}
          accounts={paymentAccounts}
          dateFrom={from}
          dateTo={to}
        />
      )
    })
  }

  const handleExportClick = () => {
    modalShow({
      header: tr('FRONTEND.TRANSACTIONS.EXPORT_CSV', 'Transactions CSV'),
      options: { size: 'medium', closeOnClickOutside: false, closeOnEscape: false, overflow: 'visible' },
      content: (
        <ExportTransactionFiles
          exportType={'CSV'}
          account={getAccount()}
          accounts={paymentAccounts}
          dateFrom={from}
          dateTo={to}
        />
      )
    })
  }

  return (
    <div className='transaction-export-buttons-container'>
      <CoreButton
        variation='tertiary'
        size='small'
        fullWidth
        LeftIcon={<TransactionsExportDocumentIcon />}
        className='transactions-export-buttons'
        title={tr('FRONTEND.TRANSACTIONS.BALANCE_STATEMENT_BUTTON', 'Balance Statement')}
        onClick={handleBalanceStatementClick}
        data-test='button-export-balance-statement'
      />
      <CoreButton
        variation='tertiary'
        size='small'
        fullWidth
        style={{ borderTop: '1px solid #fff6f5' }}
        LeftIcon={<TransactionsExportDocumentIcon />}
        className='transactions-export-buttons'
        title={tr('FRONTEND.TRANSACTIONS.EXPORT_CSV', 'Transactions CSV')}
        onClick={handleExportClick}
        data-test='button-export-csv'
      />
    </div>
  )
}
