import { Suspense, useEffect, useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { ErrorBoundary } from 'react-error-boundary'
import { withTranslation } from 'react-i18next'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useDispatch } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import ContentWrapper from '@components/ContentWrapper'
import CoreButton from '@components/CoreButton'
import CoreMenu from '@components/CoreMenu'
import CoreToaster from '@components/CoreToaster'
import Header from '@components/Header'
import Modal from '@components/ModalDialog'
import { Otp, prepareTitle } from '@components/Otp'
import SidebarRight from '@components/SidebarRight'
import { coreMenuItems } from '@constants'
import ErrorIcon from '@images/icons/error.svg?react'
import '@scss/app/app.scss'
import settings from '@settings'
import Error404 from '@views/static/Error404'
import { ThemeProvider } from 'styled-components'

import {
  selectCurrentUserStatus,
  useAppInitializerQuery,
  useAppSelector,
  useAuthQuery,
  useCardAuth,
  useLazyErrorLoggingQuery
} from 'mmfintech-backend-api'
import { GlobalContextProvider, OtpContext, isValidObject } from 'mmfintech-commons'
import { LoginStatusEnum } from 'mmfintech-commons-types'
import { Preloader } from 'mmfintech-portal-commons'

import { nonProtectedRoutes, routes } from './routes'
import theme from './theme'
import { PrivateRoute, PublicRoute, statusPaths } from './utils'

function AppInner() {
  useAppInitializerQuery(settings.languages)
  const { isLoading: isFetchingAuth, isSuccess } = useAuthQuery()
  const { fetchCardAuthToken } = useCardAuth()

  const userStatus = useAppSelector(selectCurrentUserStatus)
  const queryChallenge = useAppSelector(state => state.challenge)
  const history = useHistory()

  const dispatch = useDispatch()
  const location = useLocation()

  const [modalContent, setModalContent] = useState(null)
  const [modalHeader, setModalHeader] = useState(null)
  const [modalOptions, setModalOptions] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [sidebarInnerRightContent, setSidebarInnerRightContent] = useState(null)
  const [sidebarInnerRightOptions, setSidebarInnerRightOptions] = useState(null)
  const [sidebarInnerRightVisible, setSidebarInnerRightVisible] = useState(false)
  const [sidebarRightContent, setSidebarRightContent] = useState(null)
  const [sidebarRightOptions, setSidebarRightOptions] = useState(null)
  const [sidebarRightVisible, setSidebarRightVisible] = useState(false)
  const [otpOnSuccess, setOtpOnSuccess] = useState(null)
  const [otpOnError, setOtpOnError] = useState(null)

  const { twoFactorType } = queryChallenge || {}

  const modalHide = () => {
    setModalVisible(false)
    setModalContent(null)
    setModalOptions(null)
    setModalHeader(null)
  }
  const modalShow = ({ options, content, header }) => {
    setModalContent(content)
    setModalOptions(options)
    setModalHeader(header)
    setModalVisible(true)
  }

  const sidebarInnerRightHide = () => {
    setSidebarInnerRightVisible(false)
    setSidebarInnerRightContent(null)
    setSidebarInnerRightOptions(null)
  }

  const sidebarInnerRightShow = ({ options, content }) => {
    setSidebarInnerRightContent(content)
    setSidebarInnerRightOptions(options)
    setSidebarInnerRightVisible(true)
  }

  const sidebarRightHide = () => {
    setSidebarRightVisible(false)
    setSidebarRightContent(null)
    setSidebarRightOptions(null)
  }

  const sidebarRightShow = ({ options, content }) => {
    setSidebarRightContent(content)
    setSidebarRightOptions(options)
    setSidebarRightVisible(true)
  }

  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN

  const globalContext = {
    modalHide,
    modalShow,
    sidebarRightHide,
    sidebarRightShow,
    sidebarInnerRightHide,
    sidebarInnerRightShow
  }

  useEffect(() => {
    isValidObject(queryChallenge) && queryChallenge?.hasOwnProperty('challengeId')
      ? modalShow({
          header: prepareTitle({ twoFactorType, userStatus }),
          options: { closeOnClickOutside: false },
          content: <Otp />
        })
      : modalHide()
  }, [queryChallenge, dispatch])

  useEffect(() => {
    const body = document.getElementsByTagName('body').item(0)
    if (body) {
      if (sidebarRightVisible) {
        window && window.scrollTo(0, 0)
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
  }, [sidebarRightVisible])

  useEffect(() => {
    window.addEventListener('storage', e => {
      if (e.key === 'jeton-id' && e.newValue !== e.oldValue) {
        history.go(0)
      }
    })
  }, [])

  useEffect(() => {
    if (isSuccess) {
      fetchCardAuthToken()
    }
  }, [isSuccess])

  if (isFetchingAuth) return <Preloader />

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider context={globalContext}>
          <OtpContext.Provider
            value={{
              otpOnSuccess,
              setOtpOnSuccess,
              otpOnError,
              setOtpOnError
            }}>
            <SkeletonTheme baseColor='#e0e0e0' highlightColor='#ccc'>
              <div className='app-container'>
                {isLoggedIn() && !nonProtectedRoutes.find(route => route.path == location.pathname) && (
                  <CoreMenu items={coreMenuItems} />
                )}
                <div className='main-content'>
                  {isLoggedIn() && !nonProtectedRoutes.find(route => route.path == location.pathname) && (
                    <div className='header'>
                      <Header />
                    </div>
                  )}
                  <SidebarRight
                    content={sidebarRightContent}
                    options={sidebarRightOptions}
                    visible={sidebarRightVisible}
                    onClose={sidebarRightHide}
                  />

                  <ContentWrapper
                    sideBarInner={{
                      content: sidebarInnerRightContent,
                      options: sidebarInnerRightOptions,
                      visible: sidebarInnerRightVisible,
                      onClose: sidebarInnerRightHide
                    }}>
                    <div className='content-container'>
                      <Switch>
                        {nonProtectedRoutes.map(({ path, Component, redirect }, index) => {
                          if (redirect) {
                            return (
                              <PrivateRoute
                                key={index}
                                path={path}
                                exact
                                component={Component}
                                redirect={redirect}
                                invalidSessionRedirect={statusPaths(userStatus)}
                              />
                            )
                          }
                          return <PublicRoute key={index} path={path} exact component={Component} />
                        })}
                        {routes.map(({ path, PrivateComponent, redirect }, index) => (
                          <PrivateRoute
                            key={index}
                            path={path}
                            exact
                            component={PrivateComponent}
                            redirect={redirect}
                            invalidSessionRedirect={statusPaths(userStatus)}
                          />
                        ))}
                        <Route path='*' component={Error404} />
                      </Switch>

                      <Modal
                        header={modalHeader}
                        content={modalContent}
                        options={modalOptions}
                        visible={modalVisible}
                        onClose={modalHide}
                      />
                    </div>
                  </ContentWrapper>
                </div>
              </div>
              <CoreToaster />
            </SkeletonTheme>
          </OtpContext.Provider>
        </GlobalContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <div className='app-alert-wrapper'>
      <div className='app-alert'>
        <ErrorIcon />
        <p data-test='app-alert-unexpected-error'>An unexpected error occurred</p>
        <CoreButton
          fullWidth
          data-test='app-alert-unexpected-error-back-to-home-button'
          variation='primary'
          size='normal'
          style={{ maxWidth: '30rem' }}
          onClick={resetErrorBoundary}
          title='Go back to the home page'
        />
      </div>
    </div>
  )
}

const ThisApp = withTranslation()(AppInner)

function App() {
  const [sendError] = useLazyErrorLoggingQuery()
  return (
    <Suspense
      fallback={
        <div className='app-preload-wrapper'>
          <Preloader />
        </div>
      }>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err, { componentStack }) => sendError({ level: 'ERROR', componentStack, message: err.toString() })}
        onReset={() => {
          window.location.replace('/')
        }}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App
