import { useEffect, useState } from 'react'

import CoreButton from '@components/CoreButton'
import CoreInput from '@components/CoreInput'
import Pagination from '@components/CorePagination'
import CoreSelect from '@components/CoreSelect'
import SidebarRight from '@components/SidebarRight'
import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'
import ColumnIcon from '@images/icons/transaction-columns-icon.svg?react'
import TransactionsExportDocumentIcon from '@images/icons/transaction-export-icon.svg?react'
import ClearFiltersIcon from '@images/icons/transaction-refresh-filters-icon.svg?react'
import CalendarIcon from '@images/icons/transaction-search-calendar.svg?react'
import FiltersIcon from '@images/icons/transactions-filter-icon.svg?react'
import settings from '@settings'

import { useLazyGetTransactionsQuery, useMerchantAccounts, useTransactionColumns } from 'mmfintech-backend-api'
import {
  fixDateOnly,
  isValidArray,
  isValidObject,
  monthBackDate,
  tr,
  useDropDownMenu,
  useFilter,
  usePaginationQuery
} from 'mmfintech-commons'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import NoTransactionsMessage from './NoTransactionsMessage'
import CustomizeTableModal from './elements/CustomizeTableModal'
import { TransactionsExportButtons, TransactionsExportButtonsInterface } from './elements/TransactionsExportButtons'
import TransactionsFilter from './elements/TransactionsFilter'
import TransactionsTable from './elements/TransactionsTable'
import './styled/transactions.scss'

import SelectAccountImage from '@images/account-select.png'
import NoTransactionsImage from '@images/icons/transactions-icon.png'

function Transactions({ match }) {
  const { accounts: paymentAccounts } = useMerchantAccounts()
  const [open, setOpen] = useState<boolean>(false)
  const accountId = parseInt(match.params.accountId)
  const columns = useTransactionColumns({
    cookieDomain: settings.cookieDomain,
    enableCustomerEmail: false,
    enableFailReason: false
  })
  const [applyFilter, setApplyFilter] = useState<boolean>(false)
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false)
  const [countFilters, setCountFilters] = useState<number>(0)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [fetchTransactions, { transactions, transactionsError, transactionsFetching }] = useLazyGetTransactionsQuery({
    selectFromResult: ({ data, error, isFetching }) => ({
      transactions: isValidArray(data?.content) ? data.content : [],
      transactionsError: error,
      transactionsFetching: isFetching
    })
  })

  const isMobile = useMatchMedia({ breakpoint: breakpoints.md, prefix: 'max' })
  const isLarge = useMatchMedia({ breakpoint: 1380, prefix: 'min' })
  const isCollapsedButtons = useMatchMedia({ breakpoint: 1600, prefix: 'max' })

  const [exportParameters, setExportParameters] = useState<TransactionsExportButtonsInterface>()
  const { visible, toggleDropDown } = useDropDownMenu()

  const filters = useFilter({
    from: monthBackDate(),
    to: new Date()
  })

  const clearAll = () => {
    filters.reset()
    setApplyFilter(true)
    setSelectedAccount(null)
  }

  const pagination = usePaginationQuery({
    rowsPerPage: 10,
    reload: async (params: any, onSuccess = null) => {
      try {
        const response = await fetchTransactions(prepareFilter(params, filters)).unwrap()
        if (response) {
          onSuccess(response)
        }
      } catch (_err) {}
    }
  })

  useEffect(() => {
    if (applyFilter) {
      if (filters) {
        setExportParameters({
          accountId: filters.get('accountId') as any,
          from: filters.get('from') as any,
          to: filters.get('to') as any
        })
      }
      setCountFilters(
        Object.values(filters.filterValues).filter(
          value =>
            value !== filters.filterValues.from &&
            value !== filters.filterValues.to &&
            value !== filters.filterValues.accountId
        ).length
      )
      pagination.reload()
      setApplyFilter(false)
    }
  }, [applyFilter])

  useEffect(() => {
    if (
      filters.filterValues.from ||
      filters.filterValues.to ||
      filters.filterValues.accountId ||
      filters.filterValues.accountId === 0
    ) {
      setApplyFilter(true)
    }
  }, [filters.filterValues.from, filters.filterValues.to, filters.filterValues.accountId])

  const prepareFilter = (params: any, filters: any) => {
    return {
      ...params,
      accountId,
      ...(filters
        ? {
            ...filters.prepare(),
            ...(filters.get('statuses') ? { statuses: [...filters.get('statuses')?.split(',')] } : null),
            ...(filters.get('paymentMethods') ? { paymentMethods: [filters.get('paymentMethods')] } : []),
            ...(filters.get('currency') ? { currency: filters.get('currency') } : []),
            from: fixDateOnly(filters.get('from')),
            to: fixDateOnly(filters.get('to'))
          }
        : {}),
      sort: 'reqTime-desc,id-desc'
    }
  }

  return (
    <div className='transaction-container'>
      <SidebarRight
        content={
          <TransactionsFilter
            isMobile={!isLarge}
            filters={filters}
            accountId={accountId}
            setApplyFilter={setApplyFilter}
            modalHide={() => setOpenFilterModal(false)}
            clearAll={clearAll}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        }
        visible={openFilterModal}
        options={{ size: 'extra-large' }}
        onClose={() => setOpenFilterModal(false)}
      />
      <div className='transaction-section-header'>
        <div className='title'>{tr('FRONTEND.TRANSACTIONS.TITLE', 'Transactions')}</div>
        <div className='buttons-container'>
          {isLarge && (
            <div className='transaction-core-select-account'>
              <CoreSelect
                data-test='select-all-accounts'
                LeftIcon={<img src={SelectAccountImage} width={30} alt='' />}
                type='account'
                label={tr('FRONTEND.TRANSACTIONS.FILTER.LABEL.ALL_ACCOUNTS', 'All Accounts')}
                allAccounts={true}
                value={selectedAccount}
                options={paymentAccounts}
                onChange={(_, account) => {
                  setSelectedAccount(account)
                  if (account === null) {
                    //arbitrary value as accountId is parsed to a Number
                    filters.set('accountId', 0)
                  } else {
                    filters.set('accountId', account.id)
                  }
                }}
              />
            </div>
          )}
          {isLarge && (
            <CoreInput
              type='date'
              name='from'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.LABEL.FROM', 'From')}
              LeftIcon={<CalendarIcon />}
              className='transaction-calendar-input'
              selectsStart
              rangeDates
              maxDate={filters.get('to')}
              {...filters.registerInput('from')}
              startDate={filters.get('from')}
              endDate={filters.get('to')}
              data-test='filter-from-date'
              clearable
            />
          )}
          {isLarge && (
            <CoreInput
              type='date'
              name='to'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.LABEL.TO', 'To')}
              LeftIcon={<CalendarIcon />}
              className='transaction-calendar-input'
              selectsEnd
              rangeDates
              minDate={filters.get('from')}
              {...filters.registerInput('to')}
              startDate={filters.get('from')}
              endDate={filters.get('to')}
              data-test='filter-to-date'
              clearable
            />
          )}
          {!isMobile && (
            <div style={{ position: 'relative' }}>
              <CoreButton
                data-test='customize-modal-button'
                variation='secondary'
                size='normal'
                title={tr('FRONTEND.TRANSACTIONS.FILTER.COLUMNS_BUTTON', 'Columns')}
                width='12.4rem'
                collapsed={(isCollapsedButtons && isLarge) || isMobile}
                tooltip={isCollapsedButtons ? tr('FRONTEND.TRANSACTIONS.FILTER.COLUMNS_BUTTON', 'Columns') : ''}
                LeftIcon={<ColumnIcon />}
                CollapsedIcon={<ColumnIcon />}
                onClick={() => setOpen(!open)}
              />
              {open && <CustomizeTableModal columns={columns} onClose={() => setOpen(!open)} />}
            </div>
          )}
          {isValidObject(filters.prepare()) && (
            <CoreButton
              variation='tertiary'
              onClick={() => {
                clearAll()
              }}
              collapsed={(isCollapsedButtons && isLarge) || isMobile}
              CollapsedIcon={<ClearFiltersIcon />}
              LeftIcon={<ClearFiltersIcon />}
              tooltip={isCollapsedButtons ? tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_ALL_BUTTON', 'Reset filters') : ''}
              size='normal'
              title={tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_ALL_BUTTON', 'Reset filters')}
              data-test='reset-filter-button'
            />
          )}
          <CoreButton
            onClick={() => setOpenFilterModal(true)}
            variation='secondary'
            size='normal'
            className='transaction-filter-button'
            title={tr('FRONTEND.TRANSACTIONS.FILTER.FILTERS_BUTTON', 'Filters')}
            LeftIcon={<FiltersIcon />}
            CollapsedIcon={<FiltersIcon />}
            tooltip={isCollapsedButtons ? tr('FRONTEND.TRANSACTIONS.FILTER.FILTERS_BUTTON', 'Filters') : ''}
            collapsed={(isCollapsedButtons && isLarge) || isMobile}
            RightIcon={countFilters > 0 && <span className='transaction-filter-count-icon'>{countFilters}</span>}
          />
        </div>
      </div>
      <div className='transaction-main-row'>
        {transactionsFetching ? (
          <Spinner />
        ) : transactionsError ? (
          <ErrorDisplay error={transactionsError} />
        ) : isValidArray(transactions) ? (
          <>
            <TransactionsTable columns={columns} transactions={transactions} />
            <div className='pagination-buttons-container'>
              <Pagination {...pagination.register()} pageRange={isMobile ? 3 : 1} />
              <div style={{ position: 'relative' }}>
                {visible && <TransactionsExportButtons {...exportParameters} />}
                <CoreButton
                  variation='outline'
                  size='small'
                  className='transactions-export-button'
                  LeftIcon={<TransactionsExportDocumentIcon />}
                  title={tr('FRONTEND.TRANSACTIONS.EXPORT', 'Export')}
                  onClick={toggleDropDown}
                  data-test='button-export-csv'
                />
              </div>
            </div>
          </>
        ) : (
          <NoTransactionsMessage iconLink={NoTransactionsImage} />
        )}
      </div>
    </div>
  )
}

export default Transactions
