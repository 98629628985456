import cn from 'classnames'

import { configuration } from 'mmfintech-backend-api'
import { findCountryByLanguageCode, getCountryImageUrl } from 'mmfintech-commons'

import './flagIcon.scss'

interface FlagIconProps {
  countryCode?: string
  width?: number | string
  height?: number | string
  circle?: boolean
  className?: string
}

const FlagIcon = ({ countryCode, width = '3rem', height = '2rem', circle = false, className = '' }: FlagIconProps) => {
  const code = countryCode?.length === 2 ? findCountryByLanguageCode(countryCode) : countryCode

  return (
    <span
      data-test={`flag-icon-${countryCode}`}
      style={{ width, height }}
      className={cn('flag-icon-wrapper', className, { circle })}>
      <img src={`${configuration.getBaseUrl()}${getCountryImageUrl(code)}`} alt='' />
    </span>
  )
}

export default FlagIcon
