import { useEffect, useState } from 'react'

import cn from 'classnames'

import './coreSnowFall.scss'

type Props = {
  children: React.ReactNode
  backgroundColor?: string
  snowFalling?: boolean
  maxHeight?: number
}
const SnowFallBackground = ({ children, backgroundColor, snowFalling, maxHeight }: Props) => {
  const [mount, setMount] = useState<boolean>(true)

  useEffect(() => {
    !snowFalling
      ? setTimeout(() => {
          setMount(snowFalling)
        }, 500)
      : setMount(snowFalling)
  }, [snowFalling])
  return (
    <div
      style={{
        background: backgroundColor || 'radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)',
        maxHeight: maxHeight || ''
      }}
      className={cn('snow-fall-wrapper', { snowFalling: snowFalling })}>
      {mount && (
        <>
          {Array.from({ length: 250 }, (_, index) => (
            <div key={index} className={cn('snow-falling-effect', { disableSnow: !snowFalling })} />
          ))}
        </>
      )}
      {children}
    </div>
  )
}

export default SnowFallBackground
