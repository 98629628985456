interface IClassConfig {
    buttonClass: string;
    collapsedClass: string;
    loadButtonClass: string;
}

type TSizeConfig = {
    [key in buttonSize]: IClassConfig;
};

type TButtonConfig = {
    [key in buttonValidation]: TSizeConfig;
};

export type buttonValidation = "primary" | "secondary" | "outline" | "tertiary";

export type buttonSize = "small" | "normal" | "large" ;


const buttonConfiguration: TButtonConfig = {
    primary: {
      small: {
        buttonClass: 'small-primary-button',
        collapsedClass: 'collapsed-small-primary-button',
        loadButtonClass: 'primary-small-loading-button'
      },
      normal: {
        buttonClass: 'normal-primary-button',
        collapsedClass: 'collapsed-normal-primary-button',
        loadButtonClass: 'primary-normal-loading-button'
      },
      large: {
        buttonClass: 'large-primary-button',
        collapsedClass: 'collapsedL-large-primary-button',
        loadButtonClass: 'primary-large-loading-button'
      }
    },
    secondary: {
      small: {
        buttonClass: 'small-secondary-button',
        collapsedClass: 'collapsed-small-secondary-button',
        loadButtonClass: 'secondary-small-loading-button'
      },
      normal: {
        buttonClass: 'normal-secondary-button',
        collapsedClass: 'collapsed-normal-secondary-button',
        loadButtonClass: 'secondary-normal-loading-button'
      },
      large: {
        buttonClass: 'large-secondary-button',
        collapsedClass: 'collapsed-large-secondary-button',
        loadButtonClass: 'secondary-large-loading-button'
      }
    },
    outline: {
      small: {
        buttonClass: 'small-outline-button',
        collapsedClass: 'collapsed-small-outline-button',
        loadButtonClass: 'outline-small-loading-button'
      },
      normal: {
        buttonClass: 'normal-outline-button',
        collapsedClass: 'collapsed-normal-outline-button',
        loadButtonClass: 'outline-normal-loading-button'
      },
      large: {
        buttonClass: 'large-outline-button',
        collapsedClass: 'collapsed-large-outline-button',
        loadButtonClass: 'outline-large-loading-button'
      }
    },
    tertiary: {
      small: {
        buttonClass: 'small-tertiary-button',
        collapsedClass: 'collapsed-small-tertiary-button',
        loadButtonClass: 'tertiary-small-loading-button'
      },
      normal: {
        buttonClass: 'normal-tertiary-button',
        collapsedClass: 'collapsed-normal-tertiary-button',
        loadButtonClass: 'tertiary-normal-loading-button'
      },
      large: {
        buttonClass: 'large-tertiary-button',
        collapsedClass: 'collapsed-large-tertiary-button',
        loadButtonClass: 'tertiary-large-loading-button'
      }
    },
  }
  
export default buttonConfiguration