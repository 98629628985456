import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { breakpoints } from '@constants'

import { paths } from 'mmfintech-backend-api'
import { useMatchMedia } from './useMatchMedia'

const isPathExcluded = (pathname: string) => {
  const excludedPaths = [
    paths.banking.sendBankTransfer(),
    paths.banking.sendBatchPayment(),
    paths.banking.transfer(),
    paths.banking.sendCrypto(),
    paths.banking.sendTransfer(),
    paths.banking.sendDigitalWallet(),
    paths.banking.sendIntraAccountTransfer(),
    paths.developer()
  ]

  return excludedPaths.some(path => pathname === path)
}

const hasSpecialPathPattern = (pathname: string) => {
  const specialPatterns = ['/deposit', '/exchange', '/send', '/withdraw']

  return specialPatterns.some(pattern => pathname.substring(0, pattern.length) === pattern)
}

const useHidePaymentButtons = () => {
  const [visible, setVisible] = useState(true)
  const location = useLocation()
  const open = useMatchMedia({breakpoint:breakpoints.xl , prefix: "min"})

  useEffect(() => {
    if (open) {
      const { pathname } = location

      let stateVisibility = true // default to true

      if (isPathExcluded(pathname) || hasSpecialPathPattern(pathname)) {
        stateVisibility = false
      }

      if (stateVisibility !== visible) {
        setVisible(stateVisibility)
      }
    }
  }, [location.pathname, open])
  return visible
}

export default useHidePaymentButtons
