import { useEffect, useState } from 'react'

import { useInfiniteScroll } from '@hooks'
import ApprovedIcon from '@images/icons/approved-activity.svg?react'
import RejectedIcon from '@images/icons/attention-activity.svg?react'
import CrossIcon from '@images/icons/cross-button-icon.svg?react'
import DepositIconGreen from '@images/icons/deposit-activity-green.svg?react'
import DepositIconRed from '@images/icons/deposit-activity-red.svg?react'
import DepositIconYellow from '@images/icons/deposit-activity-yellow.svg?react'
import ExchangeIcon from '@images/icons/exchange-activity.svg?react'
import NoActivitiesIcon from '@images/icons/no-transactions.svg?react'
import OthersIcon from '@images/icons/others-icon.svg?react'
import RecipientsIcon from '@images/icons/receipt-activity.svg?react'
import InvoicesIcon from '@images/icons/receipt-activity.svg?react'
import ReviewedIcon from '@images/icons/review-activity.svg?react'
import WithdrawIconGreen from '@images/icons/send-activity-green.svg?react'
import WithdrawIconRed from '@images/icons/send-activity-red.svg?react'
import WithdrawIconYellow from '@images/icons/send-activity-yellow.svg?react'
import moment from 'moment'

import {
  paths,
  transformMerchantActivity,
  useActivities,
  useLazyGetMerchantActivitiesQuery
} from 'mmfintech-backend-api'
import { fixDateOnly, isValidArray, todayDate, tr } from 'mmfintech-commons'
import { MerchantActivityOut } from 'mmfintech-commons-types'
import { ErrorDisplay, Preloader } from 'mmfintech-portal-commons'

import { fillActivityFields } from '../../utils/helpers'
import CoreButton from '../CoreButton'
import CoreTabs from '../CoreTabs'
import './activitiesMenu.scss'

enum Category {
  Deposit_pending,
  Deposit_successful,
  Deposit_failed,
  Withdraw_pending,
  Withdraw_successful,
  Withdraw_failed,
  Exchange,
  Recipients,
  Approved,
  Rejected,
  Reviewed,
  Invoices,
  Welcome,
  Others
}
type ActivityCategories = {
  [K in keyof typeof Category]: string[]
}
type ActivityPaletteProps = {
  [K in keyof typeof Category]: {
    icon: JSX.Element
  }
}

export type ActivityComponentProps = {
  day: string
  activities: MerchantActivityOut[]
}

export function groupByAndArrange(list: MerchantActivityOut[]) {
  const map = new Map()
  isValidArray(list) &&
    list?.forEach((item: MerchantActivityOut) => {
      const day = moment(item.time).format('DD.MM.YYYY')
      const collection = map.get(day)
      if (!collection) {
        map.set(day, [item])
      } else {
        collection.push(item)
      }
    })
  return map.size > 0 ? Array.from(map) : []
}

export function extractCategoryFromActivity(activity: MerchantActivityOut) {
  const category = (Object.keys(activityCategories) as (keyof typeof activityCategories)[]).find(key =>
    activityCategories[key].includes(activity.type)
  )
  if (!category) return 'Others'
  return category
}

function ActivitiesMenu({ onClose }: { onClose: () => void }) {
  const lastWeekDate = () => {
    const date = new Date()
    date.setDate(date.getDate() - 7)
    return date
  }

  const lastMonthDate = () => {
    const date = new Date()
    date.setMonth(date.getMonth() - 1)
    return date
  }

  const lastYearDate = () => {
    const date = new Date()
    date.setFullYear(date.getFullYear() - 1)
    return date
  }

  const [filter, setFilter] = useState({ period: 'Week', date: lastWeekDate() })

  const {
    combinedData: lastActivities,
    isFetching: lastActivitiesFetching,
    readMore: fetchActivities,
    error: lastActivitiesError,
    localPage,
    totalPages
  } = useInfiniteScroll(useLazyGetMerchantActivitiesQuery, {})

  const getSectionCaption = (period: string): string => {
    switch (period) {
      case 'Month':
        return tr('FRONTEND.ACTIVITIES.CAPTION.MONTH', 'Last 30 days')
      case 'Year':
        return tr('FRONTEND.ACTIVITIES.CAPTION.YEAR', 'Last year')
    }
  }

  const arrangedActivities =
    filter.period === 'Week' ? groupByAndArrange(lastActivities) : [[getSectionCaption(filter.period), lastActivities]]

  useEffect(() => {
    if (filter) {
      let size = 10
      if (filter.period === 'Week') {
        size = 9999 // all the activity for the last week
      }
      void fetchActivities({
        size,
        filter: {
          from: fixDateOnly(filter.date),
          to: fixDateOnly(todayDate())
        },
        page: 0
      })
    }
  }, [filter])

  const fetchMore = () => {
    void fetchActivities({
      size: 10,
      filter: {
        from: fixDateOnly(filter.date),
        to: fixDateOnly(todayDate())
      }
    })
  }

  const activityPeriods = [
    { label: 'Week', onChange: lastWeekDate, text: tr('FRONTEND.ACTIVITIES.PERIOD.WEEK', 'Week') },
    { label: 'Month', onChange: lastMonthDate, text: tr('FRONTEND.ACTIVITIES.PERIOD.MONTH', 'Month') },
    { label: 'Year', onChange: lastYearDate, text: tr('FRONTEND.ACTIVITIES.PERIOD.YEAR', 'Year') }
  ]

  return (
    <div className='activities-menu-wrapper'>
      <div className='top-section'>
        <h3 data-test='activities-menu-title'>{tr('FRONTEND.ACTIVITIES.TITLE', 'Activity')}</h3>
        <CoreButton
          variation='secondary'
          data-test='activities-menu-close-button'
          onClick={onClose}
          size='small'
          collapsed
          CollapsedIcon={<CrossIcon />}
        />
      </div>

      <CoreTabs
        tabs={activityPeriods.map(period => ({ label: period.text, value: period.label }))}
        onChangeTab={selected => {
          setFilter({ period: selected, date: activityPeriods.find(period => period.label === selected).onChange() })
        }}
        preselected='Week'
      />

      <ErrorDisplay error={lastActivitiesError} />

      <div className='activity-list'>
        {lastActivitiesFetching && localPage == 0 ? (
          <Preloader />
        ) : isValidArray(arrangedActivities) && isValidArray(lastActivities) ? (
          <>
            {arrangedActivities.map(([day, activities], index: number) => (
              <ActivityComponent key={index} activities={activities} day={day} />
            ))}
            {(localPage < totalPages - 1 || lastActivitiesFetching) && (
              <CoreButton
                data-test='activities-menu-load-more-button'
                title={tr('FRONTEND.ACTIVITIES.LOAD_MORE_BUTTON', 'Load More')}
                variation='secondary'
                onClick={fetchMore}
                isLoading={lastActivitiesFetching}
              />
            )}
          </>
        ) : (
          <div className='no-activities'>
            <NoActivitiesIcon fill='black' />
            <span className='no-activities-message'>
              {tr('FRONTEND.ACTIVITIES.NO_ACTIVITIES_MESSAGE', 'No activities for the selected period.')}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default ActivitiesMenu

function ActivityComponent({ day, activities }: ActivityComponentProps) {
  const { handleActivityClick } = useActivities({
    securityPath: paths.settings(),
    handleInvoicePreview: () => {
      // no idea if this flow still works
      // dispatch(actions.invoice.findById(invoiceId))
      // dispatch(actions.invoice.fetchInvoicePreview(invoiceId))
    }
  })

  return (
    <div className='activity-column'>
      {day && (
        <div data-test={`activities-menu-date-${day}`} className='column-header'>
          {day}
        </div>
      )}
      {isValidArray(activities) &&
        activities.map((activity: MerchantActivityOut, index) => {
          const { text, time } = activity
          const category = extractCategoryFromActivity(activity)
          const { icon } = activityPalette[category]
          const data = transformMerchantActivity(activity)

          return (
            <div
              className='activity-wrapper'
              key={`${text} - ${index}`}
              onClick={() => {
                handleActivityClick(data)
              }}>
              {icon}
              <div className='activity-content'>
                <div data-test={`activity-title-${text}`} className='activity-title'>
                  {fillActivityFields(activity, tr(`FRONTEND.ACTIVITY.${activity.type}`, text.replace(/<.*?>/g, '')))}
                </div>
                <div data-test={`activity-timestamp-${text}`} className='timestamp'>
                  {day && !day.includes('Last')
                    ? moment(time).format('hh:mm')
                    : moment(time).format('DD/MM/YYYY hh:mm')}
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}

const activityCategories: ActivityCategories = {
  Withdraw_pending: ['WITHDRAWAL_PENDING'],
  Withdraw_successful: ['TRANSFER_SENT', 'WALLET_PAYMENT_COMPLETED', 'WITHDRAWAL_PENDING'],
  Withdraw_failed: ['WITHDRAWAL_REJECTED', 'WITHDRAWAL_REVERTED'],
  Deposit_pending: ['DEPOSIT_PENDING'],
  Deposit_successful: ['DEPOSIT_PROCESSED', 'TRANSFER_RECEIVED'],
  Deposit_failed: ['DEPOSIT_REJECTED', 'DEPOSIT_RETURNED'],
  Exchange: ['EXCHANGE_PROCESSED'],
  Recipients: ['CONTACT_CREATED', 'CUSTOMER_CREATED'],
  Approved: ['ACCOUNT_APPROVED', 'ISSUE_IBAN_ISSUED', 'REFUND_PROCESSED'],
  Rejected: ['ISSUE_IBAN_DECLINED', 'ISSUE_IBAN_FAILED', 'REFUND_REJECTED'],
  Reviewed: ['ISSUE_IBAN_INITIATED', 'REFUND_PENDING'],
  Invoices: [
    'ENOTAS_INVOICE_APPROVED',
    'ENOTAS_INVOICE_CANCELED',
    'ENOTAS_INVOICE_DECLINED',
    'INVOICE_CREATED',
    'INVOICE_OPENED',
    'INVOICE_PAID',
    'INVOICE_PARTIALLY_PAID'
  ],
  Welcome: ['REGISTERED_ACCOUNT'],
  Others: []
}

export const activityPalette: ActivityPaletteProps = {
  Deposit_pending: { icon: <DepositIconYellow /> },
  Deposit_successful: { icon: <DepositIconGreen /> },
  Deposit_failed: { icon: <DepositIconRed /> },
  Withdraw_pending: { icon: <WithdrawIconYellow /> },
  Withdraw_successful: { icon: <WithdrawIconGreen /> },
  Withdraw_failed: { icon: <WithdrawIconRed /> },
  Exchange: { icon: <ExchangeIcon /> },
  Recipients: { icon: <RecipientsIcon /> },
  Invoices: { icon: <InvoicesIcon /> },
  Approved: { icon: <ApprovedIcon /> },
  Rejected: { icon: <RejectedIcon /> },
  Reviewed: { icon: <ReviewedIcon /> },
  Welcome: { icon: <ApprovedIcon /> },
  Others: { icon: <OthersIcon /> }
}
