import { useContext } from 'react'

import CardOrderModal from '@components/CardOrderModal'
import CoreButton from '@components/CoreButton'
import settings from '@settings'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'
import { MerchantAccountTypeEnum } from 'mmfintech-commons-types'

import { NotOnboardedModal } from '../notOnboarded'
import './missingCard.scss'

const MissingCard = () => {
  const { modalShow, modalHide } = useContext(GlobalContext)
  const { data: merchant } = useGetMerchantQuery()
  const { accountType } = merchant || {}

  const handleOrderClick = () => {
    modalShow({
      header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
      content:
        accountType == MerchantAccountTypeEnum.PROSPECT ? (
          <NotOnboardedModal onClose={modalHide} />
        ) : (
          <CardOrderModal onClose={modalHide} />
        ),
      options: {
        closeOnClickOutside: false
      }
    })
  }

  return (
    <div className='missing-card-wrapper'>
      <video data-test='video-section-video' autoPlay muted loop poster='/images/video-poster.jpg'>
        <source src={settings.missingCardVideoUrl} />
      </video>
      <div className='missing-card-content-wrapper'>
        <div className='missing-card-wrapper'>
          <h2>{tr('FRONTEND.CARDS.MISSING.TITLE', 'Jeton Card')}</h2>
          <span>
            {tr(
              'FRONTEND.CARDS.MISSING.CONTENT',
              'Use your Jeton physical or virtual Jeton debit card for purchases in all merchants and terminals, which accepts Master cards.'
            )}
          </span>
        </div>
        <CoreButton
          className='order-card-button'
          fullWidth
          variation='tertiary'
          size='normal'
          title={tr('FRONTEND.CARDS.MISSING.BUTTON.ORDER', 'Order Card')}
          onClick={handleOrderClick}
        />
      </div>
    </div>
  )
}

export default MissingCard
