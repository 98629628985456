import CoreStatusView from '@components/CoreStatusView'
import cn from 'classnames'
import moment from 'moment'

import { getTransactionStatusName, useCurrencies } from 'mmfintech-backend-api'
import { fetchLastChunk, formatMoney, tr } from 'mmfintech-commons'
import { TransactionDirection, TransactionStatus, TransactionStatusEnum } from 'mmfintech-commons-types'

import TransactionTypeWithIcon from './elements/TransactionTypeWithIcon'
import './styled/recentTransactions.scss'

const RecentTransaction = ({
  reqTime,
  amount,
  currency,
  direction,
  status,
  trnTypeLocalizationKey,
  transactionCategory
}: {
  reqTime?: string
  amount?: number
  currency?: string
  direction?: TransactionDirection
  status?: TransactionStatus
  trnTypeLocalizationKey?: string
  transactionCategory?: string
}) => {
  const { getCurrencyPrecision } = useCurrencies()

  return (
    <div className='recent-transaction-details'>
      <div className='recent-transaction-section'>
        <TransactionTypeWithIcon
          transactionType={tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
          category={transactionCategory}
          description={moment(new Date(reqTime)).format('DD/MM/YYYY H:mm')}
        />
      </div>
      <div data-test='recent-transaction-amount' className='recent-transaction-amount'>
        <div className={cn({ cancelled: status === 'CANCELLED' })} translate='no'>
          {formatMoney(amount * (direction === 'WITHDRAW' ? -1 : 1) || 0, currency, getCurrencyPrecision(currency))}
        </div>
        <CoreStatusView status={getTransactionStatusName(status) as TransactionStatusEnum} />
      </div>
    </div>
  )
}

export default RecentTransaction
