import colors from '@scss/variables/_colors.module.scss'

import './coreStatusView.scss'
import { TransactionStatusEnum } from 'mmfintech-commons-types'

interface IStatusProps {
  status: TransactionStatusEnum
  fade?: boolean
}

const CoreStatusView = ({ status, fade }: IStatusProps) => {
  const opacityValue = fade ? 0.4 : 1
  const parsedStatus = status.toLocaleUpperCase()

  switch (parsedStatus) {
    case TransactionStatusEnum.PENDING:
    case TransactionStatusEnum.INITIAL:
      return (
        <div className='status-wrapper'>
          <span data-test='core-status-initial-circle' style={{ backgroundColor: colors.warning }}></span>
          <p data-test='core-status-initial-text' style={{ opacity: opacityValue , textTransform: "capitalize" }}>
            {status.toLocaleLowerCase()}
          </p>
        </div>
      )
    case TransactionStatusEnum.SCHEDULED:
      return (
        <div className='status-wrapper'>
          <span data-test='core-status-Scheduled-circle' style={{ backgroundColor: colors.information }}></span>
          <p data-test='core-status-Scheduled-text' style={{ opacity: opacityValue , textTransform: "capitalize" }}>
            {status.toLocaleLowerCase()}
          </p>
        </div>
      )
    case TransactionStatusEnum.CANCELLED:
    case TransactionStatusEnum.FAILED:
      return (
        <div className='status-wrapper'>
          <span data-test='core-status-failed-circle' style={{ backgroundColor: colors.error }}></span>
          <p data-test='core-status-failed-text' style={{ opacity: opacityValue , textTransform: "capitalize" }}>
            {status.toLocaleLowerCase()}
          </p>
        </div>
      )
    case TransactionStatusEnum.PROCESSED:
    case TransactionStatusEnum.CONFIRMED:
      return (
        <div className='status-wrapper'>
          <span data-test='core-status-confirmed-circle' style={{ backgroundColor: colors.success }}></span>
          <p data-test='core-status-confirmed-text' style={{ opacity: opacityValue , textTransform: "capitalize" }}>
            {status.toLocaleLowerCase()}
          </p>
        </div>
      )
    default:
      return null
  }
}

export default CoreStatusView
