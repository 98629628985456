import cn from 'classnames'

import { paths } from 'mmfintech-backend-api'
import { isValidString } from 'mmfintech-commons'

import './jetonLogo.scss'

import LogoImage from '@images/logo.svg'

interface JetonLogoProps {
  autoWidth?: boolean
  url?: string
}

export function JetonLogo({ autoWidth = false, url = '' }: JetonLogoProps) {
  return (
    <div data-test='jeton-logo-test' className={cn('jeton-logo', { 'auto-width': autoWidth })}>
      <a data-test='jeton-logo-link-test' href={isValidString(url) ? url : paths.dashboard()}>
        <img data-test='jeton-logo-img-test' src={LogoImage} alt='Logo' />
      </a>
    </div>
  )
}

export default JetonLogo
