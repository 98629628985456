import './profileAvatar.scss'

const ProfileAvatar = ({ name }: { name: string }) => {
  const getProfileInitials = (name: string): string => {
    if (!name) return ''
    const initials = name
      .split(' ')
      .map(chunk => chunk.substring(0, 1))
      .filter(char => Number.isNaN(Number(char)))
      .slice(0, 2)
      .join('')
      .toUpperCase()

    if (!initials) return name.substring(0, 1)

    return initials
  }

  return (
    <div data-test='profile-avatar' className='profile-avatar'>
      {getProfileInitials(name)}
    </div>
  )
}

export default ProfileAvatar
