import cn from 'classnames'

import { useResourcesQuery } from 'mmfintech-backend-api'

import './currencyIcon.scss'

import PlaceholderIcon from '@images/currency-placeholder.png'

interface CurrencyIconProps {
  currency?: string
  width?: number | string
  height?: number | string
  circle?: boolean
  className?: string
}

function CurrencyIcon({
  currency,
  width = '3rem',
  height = '3rem',
  circle = false,
  className = ''
}: CurrencyIconProps) {
  const { getCurrencyImage } = useResourcesQuery()
  const currencyIcon = getCurrencyImage(currency)

  return (
    <img
      data-test={`currency-icon-${currencyIcon || PlaceholderIcon}`}
      style={{ width, height }}
      src={currencyIcon || PlaceholderIcon}
      alt={currency + '-icon'}
      className={cn('currency-icon-wrapper', className, {
        circle
      })}></img>
  )
}

export default CurrencyIcon
