import { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import CoreButton from '@components/CoreButton'
import settings from '@settings'

import { configuration, paths } from 'mmfintech-backend-api'
import { GlobalContext, isValidObject, tr } from 'mmfintech-commons'

import ManageCookies from './ManageCookies'
import './styled/cookieConsent.scss'

import CookieImage from '@images/icons/cookie.svg'
import { useLocation } from 'react-router-dom'

function CookieConsent() {
  const { modalShow } = useContext(GlobalContext)
  const location = useLocation()
  const [cookies, setCookie] = useCookies(['cookie.consent']) as any

  const [cookieConsent, setCookieConsent] = useState(null)

  useEffect(() => {
    setCookieConsent(cookies['cookie.consent'])
    if (!isValidObject(cookies['cookie.consent']) && location.pathname === paths.login()) {
      modalShow({
        header: 'Manage cookies',
        options: { size: 'medium' },
        content: <ManageCookies setCookie={setCookie} />
      })
    }
  }, [cookies])

  const handleManageCookieConsent = () => {
    modalShow({
      header: 'Manage cookies',
      options: { size: 'medium' },
      content: <ManageCookies setCookie={setCookie} />
    })
  }

  return (
    !isValidObject(cookieConsent) &&
    cookieConsent !== null && (
      <div className='cookie-consent-wrap'>
        <div className='top-content'>
          <img data-test='cookie-image' src={CookieImage} alt='Cookie consent' />
          <span data-test='cookie-content'>
            {tr('FRONTEND.COOKIE_CONSENT.PERSONALISED_XP', 'We use cookies to enhance your browsing experience.')}
          </span>
        </div>

        <div className='top-buttons'>
          <CoreButton
            variation='secondary'
            size='normal'
            title={tr('FRONTEND.COOKIE_CONSENT.MANAGE_COOKIES', 'Manage')}
            onClick={handleManageCookieConsent}
            data-test='button-manage-cookies'
            type='button'
          />

          <CoreButton
            variation='primary'
            size='normal'
            title={tr('FRONTEND.COOKIE_CONSENT.ACCEPT_ALL', 'Accept All')}
            data-test='button-accept-all'
            type='button'
            onClick={() => {
              setCookie(
                'cookie.consent',
                JSON.stringify({
                  necessaryCookies: true,
                  optionalCookies: true
                }),
                {
                  days: 365,
                  maxAge: 31536000,
                  domain: configuration.isLocal() ? null : settings.cookieDomain
                }
              )
            }}
          />
        </div>
      </div>
    )
  )
}

export default CookieConsent
