import { useState } from 'react'

import CoreButton from '@components/CoreButton'
import CrossIcon from '@images/icons/cross-button-icon.svg?react'

import { isValidArray, tr } from 'mmfintech-commons'

import CheckBoxGroup from './CheckBoxGroup'
import './styled/customizeTableModal.scss'

function CustomizeTableModal({ columns, onClose }) {
  const [visible, setVisible] = useState(columns.visible)

  const toggleVisible = name => {
    if (visible.includes(name)) {
      setVisible(prevState => prevState.filter(v => v !== name))
    } else {
      setVisible(prevState => [...prevState, name])
    }
  }

  const obsoleteColumns = ['foreignTransactionId', 'customer', 'customerName']
  const filteredMeta =
    isValidArray(columns?.meta) && columns.meta.filter(metaData => !obsoleteColumns.includes(metaData.name))

  return (
    <div className='table-customize-wrapper' data-test='customize-transactions-table-modal'>
      <div className='table-customize-header'>
        <span data-test='customize-modal-title'>{tr('FRONTEND.TRANSACTIONS.COLUMNS.TITLE', 'Customize columns')}</span>
        <CoreButton
          data-test='customize-modal-close-icon'
          onClick={() => onClose()}
          collapsed
          variation='secondary'
          CollapsedIcon={<CrossIcon />}
        />
      </div>
      <CheckBoxGroup
        data-test='customize-modal-checkbox-group'
        items={filteredMeta}
        checked={name => visible.includes(name)}
        setChecked={toggleVisible}
      />

      <div className='table-customize-buttons'>
        <CoreButton
          data-test='customize-modal-apply-button'
          variation='primary'
          fullWidth
          size='normal'
          title={tr('FRONTEND.TRANSACTIONS.COLUMNS.APPLY_BUTTON', 'Apply')}
          onClick={() => {
            columns.setVisible(visible)
            onClose()
          }}
        />
      </div>
    </div>
  )
}

export default CustomizeTableModal
