import { useEffect } from 'react'

import CoreButton from '@components/CoreButton'
import CoreInput from '@components/CoreInput'
import CoreSelect from '@components/CoreSelect'
import CrossIcon from '@images/icons/cross-button-icon.svg?react'
import ClearFiltersIcon from '@images/icons/transaction-refresh-filters-icon.svg?react'
import CalendarIcon from '@images/icons/transaction-search-calendar.svg?react'

import {
  getTransactionStatusOptions,
  useCurrencies,
  useMerchantAccounts,
  usePaymentMethods
} from 'mmfintech-backend-api'
import { checkSingleValue, isValidArray, isValidObject, tr } from 'mmfintech-commons'

import './styled/transactionFilter.scss'

import SelectAccountImage from '@images/account-select.png'

function TransactionsFilter({
  accountId,
  setApplyFilter,
  modalHide = null,
  filters,
  clearAll,
  isMobile,
  selectedAccount,
  setSelectedAccount
}) {
  const { currencyOptionsSimple } = useCurrencies()
  const { paymentMethodOptions } = usePaymentMethods()
  const { accounts: paymentAccounts } = useMerchantAccounts()

  const getCurrencyOptions = () => [
    { value: '', label: tr('FRONTEND.TRANSACTIONS.FILTER.ALL_CURRENCIES', 'All') },
    ...currencyOptionsSimple
  ]

  useEffect(() => {
    if (isValidArray(paymentAccounts) && !selectedAccount) {
      let find = null
      if (accountId) {
        find = paymentAccounts.find(account => account.id === accountId)
      } else if (filters.get('accountId')) {
        find = paymentAccounts.find(account => account.id === Number(filters.get('accountId')))
      }
      setSelectedAccount(find)
    }
    // eslint-disable-next-line
  }, [paymentAccounts])

  return (
    <div className='transaction-filter-wrapper'>
      <div>
        <div className='transaction-filter-header'>
          <h3 className='' data-test='right-side-filter-title'>
            {tr('FRONTEND.TRANSACTIONS.FILTER.TITLE', 'Filters')}
          </h3>
          {typeof modalHide === 'function' && (
            <CoreButton
              data-test='right-side-filter-close-button'
              onClick={() => modalHide()}
              collapsed
              variation='secondary'
              CollapsedIcon={<CrossIcon />}
            />
          )}
        </div>
        <div className='transaction-filter-container'>
          {isMobile && (
            <CoreSelect
              data-test='filter-right-side-select-all-accounts'
              LeftIcon={<img src={SelectAccountImage} width={30} alt='' />}
              type='account'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.LABEL.ALL_ACCOUNTS', 'All Accounts')}
              allAccounts={true}
              className='transaction-core-select-account'
              value={selectedAccount}
              options={paymentAccounts}
              onChange={(_, account) => {
                if (account === null) {
                  filters.set('accountId', null)
                } else {
                  filters.set('accountId', account.id)
                }
                setSelectedAccount(account)
              }}
            />
          )}
          <div className='transaction-filter-multiple-inputs'>
            {isMobile && (
              <CoreInput
                type='date'
                name='from'
                label={tr('FRONTEND.TRANSACTIONS.FILTER.LABEL.FROM', 'From')}
                LeftIcon={<CalendarIcon />}
                className='transaction-calendar-input'
                selectsStart
                rangeDates
                {...filters.registerInput('from')}
                startDate={filters.get('from')}
                endDate={filters.get('to')}
                data-test='filter-right-side-filter-from-date'
                clearable
              />
            )}
            {isMobile && (
              <CoreInput
                type='date'
                name='to'
                label={tr('FRONTEND.TRANSACTIONS.FILTER.LABEL.TO', 'To')}
                LeftIcon={<CalendarIcon />}
                className='transaction-calendar-input'
                selectsEnd
                rangeDates
                {...filters.registerInput('to')}
                startDate={filters.get('from')}
                endDate={filters.get('to')}
                data-test='filter-right-side-filter-to-date'
                clearable
              />
            )}
          </div>
          <CoreSelect
            type={'default'}
            name='paymentMethods'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.PAYMENT_METHOD', 'Payment method')}
            {...filters.registerInput('paymentMethods')}
            value={filters.get('paymentMethods')}
            options={paymentMethodOptions}
            data-test='filter-payment-method'
          />
          <CoreSelect
            type={'default'}
            name='statuses'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.STATUS', 'Status')}
            {...filters.registerInput('statuses')}
            options={[
              { value: '', label: tr('FRONTEND.TRANSACTIONS.FILTER.ALL_STATUSES', 'All Statuses') },
              ...getTransactionStatusOptions()
            ]}
            value={filters.get('statuses')}
            data-test='filter-status'
          />
          <CoreSelect
            type={'default'}
            name='currency'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.CURRENCY', 'Currency')}
            {...filters.registerInput('currency')}
            options={getCurrencyOptions()}
            value={filters.get('currency')}
            data-test='filter-currency'
          />
          <div className='transaction-filter-multiple-inputs'>
            <CoreInput
              type='text'
              name='id'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.TRANSACTION_ID', 'ID')}
              {...filters.registerInput('id')}
              value={filters.get('id')}
              data-test='filter-id'
            />
            <CoreInput
              type='text'
              name='reference'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.REFERENCE', 'Reference')}
              {...filters.registerInput('reference')}
              value={filters.get('reference')}
              data-test='filter-reference'
            />
          </div>

          <div className='transaction-filter-multiple-inputs'>
            <CoreInput
              type='text'
              name='fromAmount'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM_AMOUNT', 'From Amount')}
              value={filters.get('fromAmount')}
              onChange={(name: string, value: string) => {
                filters.set(name, checkSingleValue(value, { validation: 'float' }))
              }}
              data-test='filter-from-amount'
            />
            <CoreInput
              type='text'
              name='toAmount'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.TO_AMOUNT', 'To Amount')}
              value={filters.get('toAmount')}
              onChange={(name: string, value: string) => {
                filters.set(name, checkSingleValue(value, { validation: 'float' }))
              }}
              data-test='filter-to-amount'
            />
          </div>
        </div>
      </div>
      <div className='transaction-filter-footer-container'>
        <CoreButton
          fullWidth
          variation='primary'
          title={tr('FRONTEND.TRANSACTIONS.FILTER.BUTTON.APPLY', 'Apply')}
          onClick={() => {
            setApplyFilter(true)
            modalHide()
          }}
          data-test='button-apply'
          size='normal'
        />
        {isValidObject(filters.prepare()) && (
          <CoreButton
            variation='secondary'
            onClick={() => {
              clearAll()
              setSelectedAccount(null)
              setApplyFilter(true)
            }}
            LeftIcon={<ClearFiltersIcon />}
            size='normal'
            title={tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_ALL_BUTTON', 'Clear All')}
            data-test='reset-filter-button'
          />
        )}
      </div>
    </div>
  )
}

export default TransactionsFilter
