import { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { paths, useGetMerchantQuery } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import './coreMenu.scss'

import MenuCollapsedIcon from '@images/icons/menu-collapsed-logo.svg'
import MenuIcon from '@images/icons/menu-icon.svg'
import LogoImage from '@images/logo.svg'

export interface MenuItem {
  imageSrc: string
  label: string
  path?: string
  localization?: string
  onClick?: () => void
  toolTip?: string
}

interface coreMenuProps {
  items: MenuItem[]
}

const CoreMenu = ({ items }: coreMenuProps) => {
  const history = useHistory()
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const { data: merchant } = useGetMerchantQuery()
  const { capabilities } = merchant || {}

  return (
    <div className='core-menu'>
      <div className={`core-menu-wrapper ${collapsed ? 'collapsed' : ''}`}>
        <div className='menu-header'>
          <img
            data-test='menu-header-image'
            className='menu-logo'
            onClick={() => history.push(paths.dashboard())}
            src={collapsed ? MenuCollapsedIcon : LogoImage}
            alt='missing logo'
          />
          <img
            data-test='menu-header-collapse-icon'
            src={MenuIcon}
            className='collapse-icon'
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
        <ul className='menu-body'>
          {items.map(({ label, path, onClick, imageSrc, localization, toolTip }) => {
            if (label === 'Cards' && !capabilities?.enableCardIssuing) {
              return null
            }
            return (
              <div key={label}>
                <NavLink
                  data-for={`side-menu${toolTip}`}
                  data-tip={toolTip}
                  data-test={`menu-links-${label}`}
                  to={path}
                  onClick={onClick || (() => history.push(path))}
                  className='menu-item-wrapper'>
                  <img data-test={`menu-link-image-${label}`} src={imageSrc} />
                  <span data-test={`menu-links-label-${label}`} className='menu-item'>
                    {tr(localization, label)}
                  </span>
                </NavLink>
                <ReactTooltip
                  id={`side-menu${toolTip}`}
                  textColor='black'
                  backgroundColor='white'
                  effect='solid'
                  className='custom-tool-tip-component'
                  disable={!collapsed}>
                  {toolTip}
                </ReactTooltip>
              </div>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default CoreMenu
