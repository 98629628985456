import CoreButton from '@components/CoreButton'
import CoreInput from '@components/CoreInput'
import CoreSelect from '@components/CoreSelect'
import CrossIcon from '@images/icons/cross-button-icon.svg?react'
import ClearFiltersIcon from '@images/icons/transaction-refresh-filters-icon.svg?react'
import CalendarIcon from '@images/icons/transaction-search-calendar.svg?react'

import { getTransactionStatusOptions } from 'mmfintech-backend-api'
import { checkSingleValue, isValidObject, tr } from 'mmfintech-commons'

import '../../../transactions/elements/styled/transactionFilter.scss'

function TransactionsFilter({ setApplyFilter, modalHide = null, filters, clearAll, isMobile }) {
  return (
    <div className='transaction-filter-wrapper'>
      <div>
        <div className='transaction-filter-header'>
          <h4 data-test='right-side-filter-title'>Filters</h4>
          {typeof modalHide === 'function' && (
            <CoreButton
              data-test='right-side-filter-close-button'
              onClick={() => modalHide()}
              collapsed
              variation='secondary'
              CollapsedIcon={<CrossIcon />}
            />
          )}
        </div>
        <div className='transaction-filter-container'>
          <div className='transaction-filter-multiple-inputs'>
            {isMobile && (
              <CoreInput
                type='date'
                name='from'
                label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM', 'From')}
                LeftIcon={<CalendarIcon />}
                className='transaction-calendar-input'
                selectsStart
                rangeDates
                {...filters.registerInput('from')}
                startDate={filters.get('from')}
                endDate={filters.get('to')}
                data-test='filter-right-side-filter-from-date'
                clearable
              />
            )}
            {isMobile && (
              <CoreInput
                type='date'
                name='to'
                label={tr('FRONTEND.TRANSACTIONS.FILTER.TO', 'To')}
                LeftIcon={<CalendarIcon />}
                className='transaction-calendar-input'
                selectsEnd
                rangeDates
                {...filters.registerInput('to')}
                startDate={filters.get('from')}
                endDate={filters.get('to')}
                data-test='filter-right-side-filter-to-date'
                clearable
              />
            )}
          </div>
          <CoreSelect
            type={'default'}
            name='statuses'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.STATUS', 'Status')}
            {...filters.registerInput('statuses')}
            options={[
              { value: '', label: tr('FRONTEND.TRANSACTIONS.FILTER.ALL_STATUSES', 'All Statuses') },
              ...getTransactionStatusOptions()
            ]}
            value={filters.get('statuses')}
            data-test='filter-status'
          />
          <CoreInput
            type='text'
            name='reference'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.REFERENCE', 'Reference')}
            {...filters.registerInput('reference')}
            value={filters.get('reference')}
            data-test='filter-reference'
          />

          <div className='transaction-filter-multiple-inputs'>
            <CoreInput
              type='text'
              name='fromAmount'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM_AMOUNT', 'From Amount')}
              value={filters.get('fromAmount')}
              onChange={(name: string, value: string) => {
                filters.set(name, checkSingleValue(value, { validation: 'float' }))
              }}
              data-test='filter-from-amount'
            />
            <CoreInput
              type='text'
              name='toAmount'
              label={tr('FRONTEND.TRANSACTIONS.FILTER.TO_AMOUNT', 'To Amount')}
              value={filters.get('toAmount')}
              onChange={(name: string, value: string) => {
                filters.set(name, checkSingleValue(value, { validation: 'float' }))
              }}
              data-test='filter-to-amount'
            />
          </div>
        </div>
      </div>
      <div className='transaction-filter-footer-container'>
        <CoreButton
          fullWidth
          variation='primary'
          title={tr('FRONTEND.TRANSACTIONS.FILTER.BUTTON.APPLY', 'Apply')}
          onClick={() => {
            setApplyFilter(true)
            modalHide()
          }}
          data-test='button-apply'
          size='normal'
        />
        {isValidObject(filters.prepare()) && (
          <CoreButton
            variation='secondary'
            onClick={() => {
              clearAll()
              setApplyFilter(true)
            }}
            LeftIcon={<ClearFiltersIcon />}
            size='normal'
            title={tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_ALL_BUTTON', 'Clear All')}
            data-test='reset-filter-button'
          />
        )}
      </div>
    </div>
  )
}

export default TransactionsFilter
