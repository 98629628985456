import MastercardLogo from '@images/icons/cardTypes/mastercard-card-type.svg?react'
import MastercardPureLogo from '@images/icons/cardTypes/mastercard-pure-logo.svg?react'
import UnionPayLogo from '@images/icons/cardTypes/unionpay-card-type.svg?react'
import UnionPayPureLogo from '@images/icons/cardTypes/unionpay-pure-icon.svg?react'
import VisaLogo from '@images/icons/cardTypes/visa-card-type.svg?react'
import VisaPureLogo from '@images/icons/cardTypes/visa-pure-icon.svg?react'

interface CardBrandProps {
  brand: string
  className?: string
  logoOnly?: boolean
}

export const CardBrand = ({ brand, className, logoOnly = false }: CardBrandProps) => {
  const getLogo = () => {
    // noinspection SpellCheckingInspection
    switch (brand) {
      case 'MASTERCARD':
        return logoOnly ? <MastercardPureLogo /> : <MastercardLogo />
      case 'VISA':
        return logoOnly ? <VisaPureLogo /> : <VisaLogo />
      case 'UNIONPAY':
        return logoOnly ? <UnionPayPureLogo /> : <UnionPayLogo />
      default:
        return null
    }
  }

  return <div className={className}>{getLogo()}</div>
}
