import { useHistory } from 'react-router-dom'

import CoreButton from '@components/CoreButton'
import JetonLogo from '@images/logo.svg?react'

import { paths } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import './styled/error404.scss'

const Error404: React.FC = () => {
  const history = useHistory()

  return (
    <div className='error-page-container'>
      <JetonLogo className='error-page-jeton-logo' onClick={() => history.push(paths.dashboard())} />
      <div>
        <div className='error-page-content-container'>
          <div className='error-header-container'>
            <div className='error-header'>4</div>
            <div className='error-header zero'>0</div>
            <div className='error-header'>4</div>
          </div>
          <div className='error-subtitle'>Page not found</div>
          <CoreButton
            type='button'
            className='error-button'
            onClick={() => history.push(paths.dashboard())}
            title={tr('FRONTEND.ERROR404.BACK_BUTTON', 'Back to Homepage')}
            data-test='back-button'
          />
        </div>
      </div>
    </div>
  )
}

export default Error404
