import { FC } from 'react'
import ReactPaginate from 'react-paginate'

import LeftArrowIcon from '@images/icons/pagination-left-arrow-icon.svg?react'
import RightArrowIcon from '@images/icons/pagination-right-arrow-icon.svg?react'

import './corePagination.scss'

interface PaginationProps {
  currentPage?: number
  setCurrentPage?: (params: any) => void
  totalPages?: number
  sort?: string
  pageRange?: number
}
const Pagination: FC<PaginationProps> = props => {
  const { currentPage, setCurrentPage, totalPages, sort, pageRange } = props
  return (
    <ReactPaginate
      breakLabel='...'
      pageCount={totalPages < 1 ? 1 : totalPages}
      pageRangeDisplayed={4}
      marginPagesDisplayed={pageRange || 3}
      forcePage={currentPage}
      nextClassName='pagination-next-button'
      pageClassName='pagination-page-button'
      previousClassName='pagination-prev-button'
      activeClassName='pagination-active-button'
      containerClassName='pagination-container'
      previousLabel={<LeftArrowIcon />}
      nextLabel={<RightArrowIcon />}
      onPageChange={data => setCurrentPage({ page: data.selected, sort })}
    />
  )
}

export default Pagination
