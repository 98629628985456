import CoreButton from '@components/CoreButton'

import { tr } from 'mmfintech-commons'

interface ReplaceCardProps {
  cardNumber?: string | number
  cardType?: string
}

const ReplaceCard = ({ cardNumber, cardType }: ReplaceCardProps) => {
  const handleCardReplace = () => {
    //todo
  }

  return (
    <div className='replace-card-wrapper'>
      <div className='replace-card-text'>
        {cardType == 'physical' ? (
          <>
            {tr(
              'FRONTEND.CARDS.SETTINGS.REPLACE_CARD.MODAL.PHYSICAL.TEXT_START',
              'This will permanently deactivate your current physical card ending in '
            )}
            {cardNumber.toString().slice(-4)}
            {tr(
              'FRONTEND.CARDS.SETTINGS.REPLACE_CARD.MODAL.PHYSICAL.TEXT_END',
              ' and we will shortly send you a replacement. This action is irreversible, do you want to continue?'
            )}
          </>
        ) : (
          tr(
            'FRONTEND.CARDS.SETTINGS.REPLACE_CARD.MODAL.VIRTUAL.TEXT_START',
            'This generates a new set of card details. Your old details will stop working. This action is irreversible, do you want to continue?'
          )
        )}
      </div>

      <CoreButton
        fullWidth
        type='button'
        variation='primary'
        size='normal'
        title={tr('FRONTEND.CARDS.SETTINGS.REPLACE_CARD.MODAL.REPLACE_BUTTON', 'Replace card')}
        onClick={handleCardReplace}
      />
    </div>
  )
}

export default ReplaceCard
