import { useContext, useState } from 'react'

import EditCardPin from '@components/CardSettings/modals/EditCardPin'
import CoreButton from '@components/CoreButton'
import CoreInput from '@components/CoreInput'

import { useActivatePhysicalCardMutation } from 'mmfintech-backend-api'
import { GlobalContext, tr, translateError } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import './activateCard.scss'

const ActivateCard = ({ prepaidCardId }: { prepaidCardId: string }) => {
  const [activateCard, { isLoading, error }] = useActivatePhysicalCardMutation()
  const [cvc, setCvc] = useState('')
  const { modalShow } = useContext(GlobalContext)

  const handleActivate = async () => {
    try {
      await activateCard({ prepaidCardId, body: { cvc } }).unwrap()
      modalShow({
        options: { closeOnClickOutside: false },
        header: tr('FRONTEND.CARDS.SETTINGS.MODAL.SET_PIN.TITLE', 'Set card PIN'),
        content: <EditCardPin prepaidCardId={prepaidCardId} />
      })
    } catch (err) {}
  }

  return (
    <div className='activate-card-wrapper'>
      <p className='activate-card-text'>
        {tr('FRONTEND.CARDS.SETTINGS.MODAL.ACTIVATE_CARD.TEXT', 'Enter the CVC code located on the back of your card')}
      </p>
      <CoreInput
        type='password'
        label={tr('FRONTEND.CARDS.SETTINGS.MODAL.ACTIVATE_CARD.INPUT_LABEL', '3 digits')}
        value={cvc}
        autoComplete='off'
        name='cvc'
        maxLength={3}
        onChange={(_: string, value: string) => {
          setCvc(value)
        }}
      />
      <ErrorDisplay error={error && translateError(error)} />
      <CoreButton
        fullWidth
        size='normal'
        variation='primary'
        title={tr('FRONTEND.CARDS.ACTIVATE.BUTTON', 'Activate')}
        isLoading={isLoading}
        onClick={handleActivate}
        disabled={cvc?.length !== 3}
      />
    </div>
  )
}

export default ActivateCard
