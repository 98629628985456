import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

import { useLazyGetTransactionsQuery } from 'mmfintech-backend-api'
import { isValidArray, usePaginationQuery } from 'mmfintech-commons'
import { ErrorDisplay, Pagination } from 'mmfintech-portal-commons'

import NoTransactionsMessage from './NoTransactionsMessage'
import RecentTransaction from './RecentTransaction'

import NoTransactionsImage from '@images/icons/transactions-icon.png'

function RecentTransactionsList({ filter, showPagination, forceReload, showRedirectButton }) {
  const [trigger, { data: transactionsResponse, error: transactionsError, isFetching: transactionsFetching }] =
    useLazyGetTransactionsQuery()

  const pagination = usePaginationQuery({
    rowsPerPage: 5,
    reload: async (params = {}, onSuccess) => {
      const transactionFilter = {
        ...filter,
        ...params
      }
      try {
        const response = await trigger(transactionFilter).unwrap()
        if (response) {
          onSuccess(response)
        }
      } catch (_err) {}
    }
  })

  useEffect(() => {
    if (forceReload) {
      pagination.reload()
    }

    // eslint-disable-next-line
  }, [forceReload])

  useEffect(() => {
    if (isValidArray(transactionsResponse?.content)) {
      showRedirectButton(true)
    } else {
      showRedirectButton(false)
    }
  }, [transactionsResponse])

  return (
    <div>
      {transactionsFetching ? (
        <Skeleton count={5} height={50} />
      ) : transactionsError ? (
        <ErrorDisplay error={transactionsError} />
      ) : (
        <div>
          {isValidArray(transactionsResponse?.content) ? (
            <div>
              {transactionsResponse.content.map(data => {
                const {
                  id,
                  reqTime,
                  amount,
                  currency,
                  direction,
                  status,
                  trnTypeLocalizationKey,
                  transactionCategory
                } = data

                return (
                  <RecentTransaction
                    data-test={`recent-transaction-${id}`}
                    key={id}
                    amount={amount}
                    currency={currency}
                    direction={direction}
                    reqTime={reqTime}
                    status={status}
                    trnTypeLocalizationKey={trnTypeLocalizationKey}
                    transactionCategory={transactionCategory}
                  />
                )
              })}
              {showPagination && <Pagination {...pagination.register()} />}
            </div>
          ) : (
            <NoTransactionsMessage iconLink={NoTransactionsImage} />
          )}
        </div>
      )}
    </div>
  )
}

export default RecentTransactionsList
