import { useContext } from 'react'

import CoreButton from '@components/CoreButton'
import { GoogleAuthenticatorInfo } from '@views/dashboard/elements/WelcomeModal/GoogleAuthenticatorInfo'

import { useChangeTwoFactorMutation } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import './googleAuthenticatorInfoModal.scss'

export const GoogleAuthenticatorInfoModal = () => {
  const [change2fa, { error: change2faError }] = useChangeTwoFactorMutation()
  const { modalHide } = useContext(GlobalContext)

  const handleSubmit = e => {
    if (e) e.preventDefault()

    change2fa({
      twoFactor: 'TOTP'
    })
  }

  return (
    <div className='google-authenticator-modal'>
      <GoogleAuthenticatorInfo isNewUser={false} />
      <div className='google-authenticator-modal-actions'>
        <ErrorDisplay error={change2faError} />
        <CoreButton
          title={tr('FRONTEND.WELCOME.MODAL.SET_UP', 'Enable now')}
          size='normal'
          fullWidth
          onClick={handleSubmit}
        />
        <CoreButton
          title={tr('FRONTEND.WELCOME.MODAL.SKIP_FOR_NOW', 'Skip for now')}
          size='normal'
          variation='tertiary'
          onClick={modalHide}
        />
      </div>
    </div>
  )
}
