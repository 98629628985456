import { SetStateAction, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import CoreCheckbox from '@components/CoreCheckbox'
import CheckIcon from '@images/icons/check.svg?react'
import PhysicalCardIcon from '@images/physical-card-without-brand.svg?react'
import VirtualCardIcon from '@images/virtual-card-without-brand.svg?react'
import cn from 'classnames'

import { useGetAllCardsQuery, useGetSupportedCardsQuery } from 'mmfintech-backend-api'
import { isValidArray, isValidObject, tr } from 'mmfintech-commons'
import { CgPrepaidCardResponse } from 'mmfintech-commons-types'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import { CardBrand } from './CardBrand'
import './cardOrderModal.scss'

interface CardOrderTypeSelectionProps {
  issuingError?: any
  supportedCardCurrenciesError?: any
  cardType?: 'virtual' | 'physical'
  selectedCardCurrency?: string
  setCardType?: (value: SetStateAction<'virtual' | 'physical'>) => void
  setSelectedCardBrand?: (value: any) => void
  selectedCardBrand?: any
}

export const CardOrderTypeSelection = ({
  issuingError,
  supportedCardCurrenciesError,
  cardType,
  selectedCardCurrency,
  setCardType,
  setSelectedCardBrand,
  selectedCardBrand
}: CardOrderTypeSelectionProps) => {
  const {
    data: supportedCards,
    error: supportedCardsError,
    isLoading: supportedCardsLoading
  } = useGetSupportedCardsQuery(null)

  const { data: activeCards, isLoading: activeCardsLoading, error: activeCardsError } = useGetAllCardsQuery(null)

  const [enablePhysicalCardBrandOptions, setEnablePhysicalCardBrandOptions] = useState<boolean>(null)
  const [enableVirtualCardBrandOptions, setEnableVirtualCardBrandOptions] = useState<boolean>(null)
  const [disablePhysicalCard, setDisablePhysicalCard] = useState<boolean | CgPrepaidCardResponse>(false)
  const [disableVirtualCard, setDisableVirtualCard] = useState<boolean | CgPrepaidCardResponse>(false)

  useEffect(() => {
    setEnablePhysicalCardBrandOptions(supportedCards?.physical?.[selectedCardCurrency]?.length > 1)
    setEnableVirtualCardBrandOptions(supportedCards?.virtual?.[selectedCardCurrency]?.length > 1)

    if (cardType === 'physical' && isValidArray(supportedCards?.physical?.[selectedCardCurrency])) {
      return setSelectedCardBrand(supportedCards?.physical?.[selectedCardCurrency][0])
    }
    if (cardType === 'virtual' && isValidArray(supportedCards?.virtual?.[selectedCardCurrency])) {
      return setSelectedCardBrand(supportedCards?.virtual?.[selectedCardCurrency][0])
    }
  }, [selectedCardCurrency, supportedCards, cardType])

  useEffect(() => {
    setDisablePhysicalCard(
      (isValidArray(activeCards) && activeCards.find(card => card.isVirtual != true)) ||
        !isValidArray(supportedCards?.physical?.[selectedCardCurrency])
    )

    setDisableVirtualCard(
      // ( isValidArray(activeCards) && activeCards.find(card => card.isVirtual == true)) ||
        !isValidArray(supportedCards?.virtual?.[selectedCardCurrency])
    )
  }, [activeCards, supportedCards, selectedCardCurrency])

  return (
    <>
      {supportedCardsLoading || activeCardsLoading ? (
        <Spinner />
      ) : (
        <div className='card-order-type-selection'>
          <ErrorDisplay error={[issuingError, supportedCardsError, supportedCardCurrenciesError, activeCardsError]} />

          <div
            className={cn('card-order-flag-wrapper', {
              open: cardType === 'physical' && enablePhysicalCardBrandOptions,
              'card-unavailable': disablePhysicalCard
            })}
            data-for={`card-order-physical-card`}
            data-tip={
              !isValidArray(supportedCards?.physical?.[selectedCardCurrency])
                ? tr(
                    'FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_ISSUING_PHYSICAL_CARD',
                    'Physical card issuing is not available for the selected currency'
                  )
                : tr('FRONTEND.CARD_ORDER.TOOLTIP_ALREADY_HAVE_PHYSICAL_CARD', 'You already have physical card.')
            }>
            <div
              className={cn('card-order-card-type-container', {
                'card-selected': cardType === 'physical'
              })}
              onClick={() => !disablePhysicalCard && setCardType('physical')}>
              <div className='card-order-check-icon'>
                <CheckIcon />
              </div>
              <div className='card-order-card-icon'>
                <PhysicalCardIcon />
                <CardBrand brand={selectedCardBrand} logoOnly className='card-brand-icon' />
              </div>
              <div className='card-order-card-content'>
                <div className='card-order-card-title'>
                  {tr('FRONTEND.DASHBOARD.CARDS_ORDER.PHYSICAL.TITLE', 'Physical card')}
                </div>
                <div className='card-order-card-text'>
                  {tr(
                    'FRONTEND.DASHBOARD.CARDS_ORDER.PHYSICAL.TEXT',
                    'A contactless debit card will be delivered to you. Your card balance will be GBP with a new account.'
                  )}
                </div>
              </div>
            </div>
            {enablePhysicalCardBrandOptions && (
              <div className={cn('card-order-choose-card-brand', { open: cardType === 'physical' })}>
                {isValidObject(supportedCards?.physical?.[selectedCardCurrency]) &&
                  supportedCards?.physical?.[selectedCardCurrency].map(brand => {
                    return (
                      <div onClick={() => setSelectedCardBrand(brand)} className='card-order-brand-wrapper'>
                        <CardBrand brand={brand} />
                        <span>{brand}</span>
                        <CoreCheckbox
                          className='card-order-checkbox'
                          value={brand as string}
                          checked={selectedCardBrand === brand}
                          type='circle'
                        />
                      </div>
                    )
                  })}
              </div>
            )}{' '}
            {disablePhysicalCard && (
              <ReactTooltip
                id={`card-order-physical-card`}
                textColor='black'
                backgroundColor='white'
                effect='solid'
                className='custom-tool-tip-component'
                padding='10px 6px'></ReactTooltip>
            )}
          </div>

          {/* virtual */}
          <div
            className={cn('card-order-flag-wrapper', {
              open: cardType === 'virtual' && enableVirtualCardBrandOptions,
              'card-unavailable': disableVirtualCard
            })}
            data-for={`card-order-virtual-card`}
            data-tip={
              !isValidArray(supportedCards?.virtual?.[selectedCardCurrency])
                ? tr(
                    'FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_ISSUING_VIRTUAL_CARD',
                    'Virtual card issuing is not available for the selected currency.'
                  )
                : tr('FRONTEND.CARD_ORDER.TOOLTIP_ALREADY_HAVE_VIRTUAL_CARD', 'You already have virtual card.')
            }>
            <div
              className={cn('card-order-card-type-container', { 'card-selected': cardType === 'virtual' })}
              onClick={() => !disableVirtualCard && setCardType('virtual')}>
              <div className='card-order-check-icon'>
                <CheckIcon />
              </div>
              <div className='card-order-card-icon'>
                <VirtualCardIcon />
                <CardBrand brand={selectedCardBrand} logoOnly className='card-brand-icon' />
              </div>
              <div className='card-order-card-content'>
                <div className='card-order-card-title'>
                  {tr('FRONTEND.DASHBOARD.CARDS_ORDER.VIRTUAL.TITLE', 'Virtual card')}
                </div>
                <div className='card-order-card-text'>
                  {tr(
                    'FRONTEND.DASHBOARD.CARDS_ORDER.VIRTUAL.TEXT',
                    'Get virtual card and manage your online payments. Your card balance will be GBP with a new account.'
                  )}
                </div>
              </div>
            </div>
            {enableVirtualCardBrandOptions && (
              <div className={cn('card-order-choose-card-brand', { open: cardType === 'virtual' })}>
                {isValidObject(supportedCards?.virtual) &&
                  supportedCards?.virtual?.[selectedCardCurrency].map(brand => {
                    return (
                      <div onClick={() => setSelectedCardBrand(brand)} className='card-order-brand-wrapper'>
                        <CardBrand brand={brand} />
                        <span>{brand}</span>
                        <CoreCheckbox
                          className='card-order-checkbox'
                          value={brand as string}
                          checked={selectedCardBrand === brand}
                          type='circle'
                        />
                      </div>
                    )
                  })}
              </div>
            )}
          </div>
        </div>
      )}
      {disableVirtualCard && (
        <ReactTooltip
          id='card-order-virtual-card'
          textColor='black'
          backgroundColor='white'
          effect='solid'
          className='custom-tool-tip-component'
          padding='10px 6px'
          place='right'
        />
      )}
    </>
  )
}
