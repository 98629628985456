import { useContext, useState } from 'react'

import CoreButton from '@components/CoreButton'
import settings from '@settings'

import { configuration } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'
import { SwitchInput } from 'mmfintech-portal-commons'

import './styled/manageCookies.scss'

function ManageCookies({ setCookie }) {
  const { modalHide } = useContext(GlobalContext)

  const [optionalCookies, setOptionalCookies] = useState(true)

  return (
    <div className='manage-cookies-wrapper' data-test='manage-cookies-modal'>
      <div className='necessary-cookies-wrapper'>
        <div>
          <h4 data-test='manage-cookie-title' className='mb-4'>
            {tr('FRONTEND.COOKIE_CONSENT.NEED_COOKIES_TITLE', 'Necessary Cookies')}
          </h4>
          <p data-test='manage-cookie-content'>
            {tr(
              'FRONTEND.COOKIE_CONSENT.NEED_COOKIES',
              'We need to use these cookies to make our website work. These are all necessary for us to function and cannot be switched off in our systems. They are for setting responses to actions made by you, such as setting your privacy preferences, logging in or filling forms.'
            )}
          </p>
        </div>
        <div></div>
      </div>

      <div className='optional-cookies-wrapper'>
        <div>
          <h4 data-test='manage-cookie-privacy-title' className='mb-4'>
            {tr('FRONTEND.COOKIE_CONSENT.ST_COOKIE_POLICY', 'Jeton Wallet Cookies Policy')}
          </h4>
          <p data-test='manage-cookie-privacy-content' className='mb-4'>
            {tr(
              'FRONTEND.COOKIE_CONSENT.COUNT_VISITS',
              'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. All information that our cookies collect is aggregated and anonymous.'
            )}
          </p>
          <p data-test='manage-cookie-privacy-page-links'>
            {tr(
              'FRONTEND.COOKIE_CONSENT.SEE_PAGES_AND_LINKS',
              "These also allow us to see the pages and links you have visited so we can prevent relevant ads. We don't store directly personal information but identify your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising."
            )}
          </p>
        </div>

        <div>
          <SwitchInput checked={optionalCookies} onChange={e => setOptionalCookies(e.target.checked)} />
        </div>
      </div>

      <CoreButton
        variation='primary'
        title={tr('FRONTEND.COOKIE_CONSENT.SAVE', 'Save')}
        fullWidth
        size='normal'
        onClick={() => {
          setCookie(
            'cookie.consent',
            JSON.stringify({
              necessaryCookies: true,
              optionalCookies
            }),
            {
              days: 365,
              maxAge: 31536000,
              domain: configuration.isLocal() ? null : settings.cookieDomain
            }
          )

          modalHide()
        }}
        data-test='button-save'
      />
    </div>
  )
}

export default ManageCookies
