import React from 'react'

import './coreCheckbox.scss'

type CheckboxType = 'check' | 'dash' | 'circle'
interface checkboxProps {
  type: CheckboxType
  value: string
  label?: string
  checked?: boolean
  onClick?: (checked: boolean) => void
  fontStyle?: React.CSSProperties
  disabled?: boolean
  style?: React.CSSProperties
  className?: string
}

const CoreCheckbox = ({
  type = 'check',
  value,
  label,
  onClick,
  checked = false,
  fontStyle,
  disabled,
  style,
  className,
  ...props
}: checkboxProps) => {
  const handleClick = event => {
    event.stopPropagation()
    onClick && onClick(!checked)
  }

  return (
    <div
      data-test={`check-button-${value}`}
      style={{ ...style }}
      className={`${className} ${
        type === 'check' ? 'check-checkbox' : type === 'dash' ? 'dash-checkbox' : 'circle-checkbox'
      }`}>
      <input
        onChange={handleClick}
        disabled={disabled}
        checked={checked}
        value={value}
        id={value || ''}
        type='checkbox'
        {...props}
      />
      {label ? (
        <label htmlFor={value}>
          <span className='check-box-component'></span>
          <p data-test={`checkbox-label-${label}`} style={{ ...fontStyle }}>
            {label}
          </p>
        </label>
      ) : (
        <label htmlFor={value}>
          <span className='check-box-component'></span>
        </label>
      )}
    </div>
  )
}

export default React.memo(CoreCheckbox)
