import { useContext, useEffect, useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import LanguageSettings from '@components/LanguageMenu'
import NotificationsIcon from '@images/icons/notifications.svg?react'

import { GlobalContext, tr } from 'mmfintech-commons'

import ActivitiesMenu from '../ActivitiesMenu'
import ProfileDropdown from '../ProfileDropdown'
import './headerIcons.scss'

interface HeaderIconsProps {
  merchantName: string
}

function HeaderIcons({ merchantName }: HeaderIconsProps) {
  const menuRef = useRef<HTMLInputElement>(null)
  const { sidebarRightShow, sidebarRightHide } = useContext(GlobalContext)

  const [visibleActivities, setVisibleActivities] = useState<boolean>(false)
  const [visibleSettings, setVisibleSettings] = useState<boolean>(false)
  const [visibleLanguage, setVisibleLanguage] = useState<boolean>(false)

  const toggleDropdowns = (type: 'settings' | 'activities' | 'language') => {
    switch (type) {
      case 'settings':
        setVisibleSettings(prevVisibility => !prevVisibility)
        setVisibleActivities(false)
        setVisibleLanguage(false)
        break
      case 'activities':
        setVisibleActivities(prevVisibility => !prevVisibility)
        setVisibleSettings(false)
        setVisibleLanguage(false)
        break
      case 'language':
        setVisibleLanguage(prevVisibility => !prevVisibility)
        setVisibleActivities(false)
        setVisibleSettings(false)
        break
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setVisibleSettings(false)
        setVisibleActivities(false)
        setVisibleLanguage(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
    // eslint-disable-next-line
  }, [visibleActivities, visibleSettings])

  const handleOpenSidebarRight = (): void => {
    sidebarRightShow({
      content: <ActivitiesMenu onClose={sidebarRightHide} />,
      options: { size: 'extra-large', closeOnClickOutside: true }
    })
  }
  return (
    <div className='header-icons-wrapper' ref={menuRef}>
      <LanguageSettings dropDownOpened={visibleLanguage} onClick={() => toggleDropdowns('language')} />
      <ProfileDropdown
        merchantName={merchantName}
        onClick={() => toggleDropdowns('settings')}
        dropDownOpened={visibleSettings}
      />
      <div
        data-for='tooltips-activity-menu'
        data-tip='activities'
        className='activity-button'
        onClick={handleOpenSidebarRight}>
        <div>
          <NotificationsIcon title={tr('FRONTEND.HEADER.ICON.ACTIVITIES', 'Activities')} />
        </div>

        <ReactTooltip
          delayShow={200}
          id='tooltips-activity-menu'
          className='custom-tool-tip-component'
          textColor='black'
          backgroundColor='white'
          effect='solid'
        />
      </div>
    </div>
  )
}

export default HeaderIcons
