import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import ProfileAvatar from '@components/ProfileAvatar'
import DownloadImage from '@images/icons/profile-download-icon.svg?react'
import settings from '@settings'

import { useGetLogoQuery, useUpdateLogoMutation } from 'mmfintech-backend-api'
import { checkImageUpload } from 'mmfintech-commons'
import { ErrorDisplay, FileInput } from 'mmfintech-portal-commons'

import CoreButton from '../CoreButton'
import './merchantLogo.scss'

interface merchantLogoProps {
  merchantName: string
  canUpload?: boolean
}
function MerchantLogo({ merchantName, canUpload = false }: merchantLogoProps) {
  const [logoError, setLogoError] = useState(null)

  const { data: merchantLogo, isFetching: merchantLogoFetching, error: merchantLogoError } = useGetLogoQuery()
  const [updateLogo, { error: updateLogoError }] = useUpdateLogoMutation()

  const convertFileToBase64 = (file: Blob): Promise<string> => {
    const reader = new FileReader()
    return new Promise<string>(resolve => {
      reader.addEventListener('load', () => resolve(String(reader.result)))
      reader.readAsDataURL(file)
    })
  }
  const handleChange = list => {
    setLogoError(null)
    convertFileToBase64(list[0]).then(fileData => {
      updateLogo(fileData)
    })
  }

  const handleError = errors => setLogoError(checkImageUpload(errors))

  return (
    <div className='merchant-logo-container'>
      {merchantLogoFetching ? (
        <div className='logo-loader'>
          <Skeleton height={40} width={40} style={{ borderRadius: '50%' }} />
        </div>
      ) : (
        <div className='logo-inner'>
          <FileInput
            maxNumber={1}
            onChange={handleChange}
            onError={handleError}
            maxFileSize={settings.logoMaxFileSize}
            acceptType={settings.logoAcceptType}>
            {({ onFileUpload }) =>
              merchantLogo ? (
                <div className='merchant-logo-container'>
                  <img src={merchantLogo} className='profile-avatar' alt='' />
                  {canUpload && (
                    <CoreButton
                      className='profile-core-button-download'
                      variation='secondary'
                      size='small'
                      collapsed
                      onClick={onFileUpload}
                      data-test='logo-image'
                      CollapsedIcon={<DownloadImage />}
                    />
                  )}
                </div>
              ) : (
                <div className='merchant-logo-container'>
                  <ProfileAvatar name={merchantName} />
                  {canUpload && (
                    <CoreButton
                      className='profile-core-button-download'
                      variation='secondary'
                      size='small'
                      collapsed
                      onClick={onFileUpload}
                      data-test='logo-image'
                      CollapsedIcon={<DownloadImage />}
                    />
                  )}
                </div>
              )
            }
          </FileInput>
        </div>
      )}
      <ErrorDisplay error={[logoError, merchantLogoError, updateLogoError]} className='text-center' />
    </div>
  )
}

export default MerchantLogo
